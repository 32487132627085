import { DocumentSnapshot, QuerySnapshot , DocumentData} from '@firebase/firestore-types';
import { removeNullValues } from './shared';
import { Procedure, ProcedureData } from '../../types/procedures';

export const transformProcedure = (document: DocumentSnapshot): Procedure | null => {
  const data = document.data();

  if (!data) {
    throw new Error(`Procedure ${document.id} has invalid data!`);
  }

  return {
    id: document.id,
    ...data as ProcedureData,
    createdAt: data.createdAt.toDate(),
    updatedAt: data.updatedAt.toDate()
  };
};

export const transformProcedures = (querySnapshot: QuerySnapshot<DocumentData>): Procedure[] => {
  if (querySnapshot.empty) {
    return [];
  }

  return querySnapshot
    .docs
    .map(transformProcedure)
    .filter(removeNullValues) as Procedure[];
};
