import firebase from 'firebase/app';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as sessionStorage from '../storage/auth';
import { auth } from '../firebase';

import {
  AuthContextFunctions,
  AuthContextValueAndFunctions,
  AuthContextValues,
  AuthResetPasswordData,
  AuthResetPasswordVerificationData,
  AuthSendInvitationEmailData,
  AuthSendResetPasswordEmailData,
  AuthSignInData,
  AuthSignInWithEmailLinkData,
  AuthSignUpData,
  AuthVerifySignInLinkData
} from './auth-types';
import { useDoctor } from '../data-hooks/doctors';
import { getAuthEmailActionFromAuthQueryParams, getAuthQueryParams } from './Authutilities';

const defaultValues: AuthContextValues = {
  isLoading: true,
  error: null,
  user: null,
  userData: null,
  emailAction: null,
  queryParams: null,
  resetPasswordAction: null,
  signInAction: null,
  providers: [],
  isAdmin: false
};

const defaultFunctions: AuthContextFunctions = {
  signInWithEmailAndPassword: async () => undefined,
  signUpWithEmailAndPassword: async () => undefined,
  signOut: async () => undefined,
  sendResetPasswordEmail: async () => undefined,
  verifySignInLink: async () => undefined,
  signInWithEmailLink: async () => undefined,
  verifyResetPasswordCode: async () => undefined,
  resetPassword: async () => undefined,
  sendInvitationEmail: async () => undefined
};

const defaultValuesAndFunctions: AuthContextValueAndFunctions = {
  ...defaultValues,
  ...defaultFunctions
};

const AuthContext = React.createContext<AuthContextValueAndFunctions>(defaultValuesAndFunctions);

export const AuthContextProvider: FC = ({ children }) => {
  const [state, setState] = useState<AuthContextValues>(defaultValuesAndFunctions);
  const { doctor: userData } = useDoctor(state.user?.uid as string);

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setState({
          ...defaultValues,
          isLoading: false
        });
        sessionStorage.signOut();
        return;
      }

      const tokenResult = await user.getIdTokenResult();
      console.log('TOKEN CHANGED USER ROLE:', tokenResult.claims.role);

      const providers = user.providerData.reduce((acc, provider) => {
        if (provider) {
          return [...acc, provider.providerId];
        }

        return acc;
      }, [] as string[]);

      setState({
        ...defaultValues,
        user,
        isLoading: false,
        providers,
        isAdmin: tokenResult.claims.role === 'application-admin' // TODO Check
      });

      sessionStorage.signIn();
    });
  }, []);

  const signInWithEmailAndPassword = useCallback(async ({ email, password }: AuthSignInData) => {
    setState(defaultValues);
    await auth.signOut();

    try {
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      setState({
        ...defaultValues,
        isLoading: false,
        error
      });
    }
  }, []);

  const signUpWithEmailAndPassword = useCallback(async (data: AuthSignUpData) => {
    setState(defaultValues);
    await auth.signOut();

    try {
      await auth.createUserWithEmailAndPassword(data.email, data.password);
    } catch (error) {
      setState({
        ...defaultValues,
        isLoading: false,
        error
      });
    }
  }, []);

  const sendResetPasswordEmail = useCallback(async ({ email }: AuthSendResetPasswordEmailData) => {
    try {
      // TODO replace with cloud function
      await auth.sendPasswordResetEmail(email);

      setState({
        ...defaultValues,
        isLoading: false,
        resetPasswordAction: {
          email,
          emailSent: true,
          success: false
        }
      });
    } catch (error) {
      setState({
        ...defaultValues,
        isLoading: false,
        error
      });
    }
  }, []);

  const verifyResetPasswordCode = useCallback(async ({ oobCode }: AuthResetPasswordVerificationData) => {
    try {
      const email = await auth.verifyPasswordResetCode(oobCode);

      setState({
        ...defaultValues,
        isLoading: false,
        resetPasswordAction: {
          email,
          emailSent: true,
          success: false
        }
      });
    } catch (error) {
      setState({
        ...defaultValues,
        isLoading: false,
        error
      });
    }
  }, []);

  const resetPassword = useCallback(async ({ oobCode, password, email }: AuthResetPasswordData) => {
    try {
      await auth.confirmPasswordReset(oobCode, password);

      setState({
        ...defaultValues,
        isLoading: false,
        resetPasswordAction: {
          email,
          emailSent: true,
          success: true
        }
      });
    } catch (error) {
      setState({
        ...defaultValues,
        isLoading: false,
        error
      });
    }
  }, []);

  const sendInvitationEmail = useCallback(async ({ email, url }: AuthSendInvitationEmailData) => {
    try {
      // TODO replace with cloud function
      await auth.sendSignInLinkToEmail(email, {
        handleCodeInApp: true,
        url
      });

      setState({
        ...defaultValues,
        isLoading: false
      });
    } catch (error) {
      setState({
        ...defaultValues,
        isLoading: false,
        error
      });
    }
  }, []);

  const verifySignInLink = useCallback(async ({ url }: AuthVerifySignInLinkData) => {
    try {
      const isSignInLink = await auth.isSignInWithEmailLink(url);

      setState({
        ...defaultValues,
        isLoading: false,
        signInAction: {
          isValidUrl: isSignInLink
        }
      });
    } catch (error) {
      setState({
        ...defaultValues,
        isLoading: false,
        error
      });
    }
  }, []);

  const signInWithEmailLink = useCallback(async ({
                                                   email,
                                                   url
                                                 }: AuthSignInWithEmailLinkData): Promise<void> => {
    try {
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await auth.signInWithEmailLink(email, url);
    } catch (error) {
      setState({
        ...defaultValues,
        isLoading: false,
        error
      });
    }
  }, []);

  const signOut = useCallback(async () => {
    setState(defaultValues);
    await auth.signOut();
    sessionStorage.signOut();
  }, []);

  const queryParams = useMemo(() =>
      getAuthQueryParams(window.location.search)
    , []);

  const emailAction = useMemo(() =>
      getAuthEmailActionFromAuthQueryParams(queryParams)
    , [queryParams]);

  // console.log('emailAction', emailAction, queryParams)
  console.log(state)

  const contextValue: AuthContextValueAndFunctions = {
    ...state,
    userData: userData || null,
    signInWithEmailAndPassword,
    signUpWithEmailAndPassword,
    signOut,
    sendResetPasswordEmail,
    verifySignInLink,
    signInWithEmailLink,
    verifyResetPasswordCode,
    resetPassword,
    sendInvitationEmail,
    queryParams,
    emailAction
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValueAndFunctions => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('You must use auth within AuthContextProvider!');
  }

  return authContext;
};

export const useAuthUser = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('You must use auth within AuthContextProvider!');
  }

  if (!authContext.user || !authContext.userData) {
    throw new Error('No signed in user!');
  }

  return {
    user: authContext.user,
    userData: authContext.userData
  };
};
