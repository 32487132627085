import React, { FC, useCallback, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { errorMessage } from '../../errors/errors';
import { useAuth } from '../../contexts/AuthContext';
import { patientCreate } from '../../data-hooks/patients';
import { useWizard } from '../../contexts/WizardContext';
import { LoadingSpinner } from '../LoadingSpinner';

interface AddPatientValues {
  fullName: string;
  personalId: string;
}

export const AddPatient: FC = (): JSX.Element => {
  const {
    updateStepData,
  } = useWizard();

  const { register, handleSubmit, formState: { errors } } = useForm<AddPatientValues>();
  const { error, user, isLoading } = useAuth();
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const history = useHistory();

  const onSubmit = useCallback(async (data: AddPatientValues) => {
    setFormIsLoading(true);

    const patientData: AddPatientValues = {
      ...data,
    };

    try {
      await patientCreate(user?.uid as string, patientData);
      setFormIsLoading(false);
      history.push(`/patients/${patientData.personalId}/profile`);
    } catch (e) {
      console.log(e);
      setFormIsLoading(false);
    }
    updateStepData({
      wizardData: {
        fullName: data.fullName,
        personalId: data.personalId,
      }
    });

  }, [history, updateStepData, user?.uid]);

  return (
    <div className="doctors-content">
      <div className="content content-fixed">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <NavLink to="/">DentDoc</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Добавяне на пациент</li>
                </ol>
              </nav>
              <h4 className="mg-b-0">Добавяне на пациент</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div className="sign-wrapper">
            <div className="wd-100p">
              {/*<h3 className="tx-color-01 mg-b-5">Добави пациент</h3>*/}
              <p className="tx-color-03 tx-16 mg-b-40">Попълнете формите, за да добавите нов пациент към
                системата.</p>
              <div className="mt-3">
                <p className="text-danger">{error ? errorMessage(error) : ''}</p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="fullName-input">Три имена:</label>
                  <input
                    id="fullName-input"
                    type="text"
                    {...register("fullName", { required: 'Моля въведете ЕГН' })}
                    className="form-control"
                    placeholder="Въведете три имена"
                  />
                  {errors.fullName && <p className="text-danger">Това поле е задължително!</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="personalId-input">ЕГН:</label>
                  <input
                    id="personalId-input"
                    type="number"
                    {...register("personalId", { required: 'Моля въведете ЕГН' })}
                    className="form-control"
                    placeholder="Въведете ЕГН"
                  />
                  {errors.personalId && <p className="text-danger">Това поле е задължително!</p>}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block">
                  {isLoading || formIsLoading ? <LoadingSpinner dark={true}/> : 'Запази'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
