export enum AppRoutes {
  // Authentication
  AUTH_FORM = '/',
  FORGOT_PASSWORD = '/forgot-password',
  UPDATE_PASSWORD = '/update-password',
  CHANGE_PASSWORD = '/change-password',
  INVITATION_PAGE = '/invitation/:email',

  // Admin
  DASHBOARD = '/dashboard',
  DOCTORS = '/doctors',
  ADD_DOCTOR = '/doctors/add',

  // Doctor
  DOCTOR_PROFILE = '/doctors/:doctorId/profile', // TODO Check
  EDIT_DOCTOR = '/doctors/:doctorId/edit',

  // Patients
  PATIENTS = '/patients',
  PATIENT_PROFILE = '/patients/:patientId/profile',
  ADD_PATIENT = '/patients/add',
  EDIT_PATIENT = '/patients/:patientId/edit',

  // PROCEDURES
  START_PROCEDURE = '/patients/:patientId/start-procedure',
  WIZARD = '/patients/:patientId/procedure/:procedureId/:step',
  WIZARD_FINISH = '/finish-wizard'
}
