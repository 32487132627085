import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { NavProfile } from './NavProfile';
import { useAuth } from '../../contexts/AuthContext';

export const NavBar: FC = (): JSX.Element => {
  const { isAdmin } = useAuth();
  return (
    <header className="navbar navbar-header navbar-header-fixed">
      {/*<a href="" id="mainMenuOpen" className="burger-menu">*/}
      {/*  <i data-feather="menu"/>*/}
      {/*</a>*/}
      <div className="navbar-brand">
        <NavLink to='/patients' className='nav-link df-logo'>InformDental</NavLink>
      </div>
      <div id="navbarMenu" className="navbar-menu-wrapper">
        <div className="navbar-menu-header">
          <NavLink to='/' className='nav-link'>Inform
            <span>Dental</span>
          </NavLink>
          {/*<a id="mainMenuClose" href="#">*/}
          {/*  <i data-feather="x"/>*/}
          {/*</a>*/}
        </div>
        <ul className="nav navbar-menu">
          {/*<li className='nav-item'>*/}
          {/*  <NavLink to='/dashboard' className='nav-link'>*/}
          {/*    Дашборд*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          <li className='nav-item' style={{ display: !isAdmin ? 'none' : '' }}>
            <NavLink to='/doctors' className='nav-link'>
              Лекари
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to='/patients' className='nav-link'>
              Пациенти
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="navbar-right">
        <NavProfile/>
      </div>
    </header>
  );
};
