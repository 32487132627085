import useSWR, { mutate as globalMutate }  from 'swr';
import {
  createPatient,
  deletePatient,
  getPatient,
  getPatients,
  updatePatient
} from '../firebase/firestore/patients';
import { DOCTORS, PATIENTS } from '../constants/firebase/collections';
import { CreatePatientData, OptionalPatientData } from '../types/patients';

const db = {
  createPatient,
  deletePatient,
  getPatient,
  getPatients,
  updatePatient
}

export const usePatients = (doctorId: string) => {
  const { data, error } = useSWR(
    `${DOCTORS}/${doctorId}/${PATIENTS}`, () =>  db.getPatients(doctorId));

  return {
    userData: data,
    isLoading: !error && !data,
    error,
  };
};

export const usePatient = (pin: string, doctorId: string) => {
  const { data, error, mutate } = useSWR(
    `${DOCTORS}/${doctorId}/${PATIENTS}/${pin}`, () =>  db.getPatient(doctorId, pin));

  return {
    userData: data,
    isLoading: !error && !data,
    error,
    patientUpdate: async (newData: OptionalPatientData) => {
      await  db.updatePatient(doctorId, pin, newData);
      await mutate();
    },
    patientDelete: async () => {
      await  db.deletePatient(pin, doctorId);
      await mutate();
      await globalMutate(`${DOCTORS}/${doctorId}/${PATIENTS}`);
    }
  };
};

export const patientCreate = async (doctorId: string, data: CreatePatientData) => {
  await db.createPatient(doctorId, data.personalId, data);
  await globalMutate(`${DOCTORS}/${doctorId}/${PATIENTS}`);
};
