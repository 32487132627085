import React, { FC, useCallback } from 'react';
import { useWizard } from '../../contexts/WizardContext';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export const FinishWizard: FC = (): JSX.Element => {
  const { uploadProcedureData, patientData } = useWizard();
  const {user} = useAuth()
  const history = useHistory();

  const saveProcedureData = useCallback(async () => {

    try {
      await uploadProcedureData();
    } catch (e) {
      console.log(e);
    }
      history.push('/patients');
  }, [history, uploadProcedureData]);

  return (
    <div className="content content-fixed content-auth-alt">
      <div className="container d-flex justify-content-center ht-100p">
        <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
          <div className="wd-80p wd-sm-300 mg-b-15">
            <div className="d-flex justify-content-center">
              <div className='nav-link df-logo'>InformDental</div>
            </div>
          </div>
            <p>Пациент: {patientData.fullName}</p>
            <p>Емайл на лекар: {user?.email}</p>
          <div className="d-flex">
            <button
              onClick={() => saveProcedureData()}
              className="btn btn-success my-2">
              Запазване
            </button>
          </div>
          <span className="tx-12 tx-color-03">
            Информационен текст
            <a href="https://google.bg">{' '}informdental.com
          </a>
          </span>
        </div>
      </div>
    </div>
  );
};
