import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { WizardContextFunctions, WizardContextValues } from '../types/wizard/wizard';
import { procedureCreate } from '../data-hooks/procedures';
import { ProcedureType, ProcedureTypeEnum } from '../types/procedures';
import { getWizardState, setWizardState } from '../storage/wizard-state';
import { PatientData } from '../types/patients';
import { useAuth } from './AuthContext';
import { useDoctor } from '../data-hooks/doctors';
import { LoadingScreen } from '../components/LoadingScreen';

const defaultValues: WizardContextValues = {
  loading: false,
  patientData: {
    fullName: '',
    personalId: '',
  },
  data: undefined
};

const defaultFunctions: WizardContextFunctions = {
  resetState: () => undefined,
  updateStepData: (data) => undefined,
  updatePatientData: (data) => undefined,
  uploadProcedureData: () => undefined,
  updateProcedureType: (data) => undefined,
};

export const WizardContext = React.createContext<WizardContextValues & WizardContextFunctions>({
  ...defaultValues,
  ...defaultFunctions
});

export const WizardContextProvider: FC = ({ children }) => {
  const storageState = getWizardState();
  const { user } = useAuth();
  const userId = user?.uid as string;
  const [state, setState] = useState<WizardContextValues>(storageState || defaultValues);

  const { doctor } = useDoctor(userId);


  useEffect(() => {
    setWizardState(state);
  });

  const updateStepData = useCallback((data: ProcedureType & PatientData) => {
    setState({
      ...state,
      data: {
        ...state.data,
        ...data
      }
    });
  }, [state]);

  const updatePatientData = useCallback((data: PatientData) => { // TODO ProcedureType and remove fullName and Id from the wizard?
    setState({
      ...state,
      patientData: data
    });
  }, [state]);

  const uploadProcedureData = useCallback(async () => {
    if (!state.procedureType || !state.data) {
      return;
    }

    setState({
      ...state,
      loading: true
    });

    if (!doctor) {
      return (<LoadingScreen/>);
    }

    await procedureCreate(
      userId,
      state.patientData.personalId,
      {
        doctorID: userId,
        doctorName: `${doctor?.firstName} ${doctor.lastName}`,
        doctorEmail: doctor.email,
        patientID: state.patientData.personalId,
        type: state.procedureType,
        data: {
          ...state.data
        }
      }
    );

    setState({
      ...state,
      loading: false
    });
  }, [doctor, state, userId]);

  const updateProcedureType = useCallback((data: ProcedureTypeEnum) => { // TODO ProcedureType and remove fullName and Id from the wizard?
    setState({
      ...state,
      procedureType: data
    });
  }, [state]);

  const resetState = useCallback(() => {
    setState(defaultValues);
  }, [setState]);

  const value: WizardContextValues & WizardContextFunctions = useMemo(() => ({
    ...state,
    resetState,
    user,
    updateStepData,
    uploadProcedureData,
    updateProcedureType,
    updatePatientData
  }), [resetState, state, updatePatientData, updateProcedureType, updateStepData, uploadProcedureData, user]);

  // if (state.loading) {
  //   return (<LoadingScreen/>);
  // } // TODO check

  return (
    <WizardContext.Provider value={value}>
      {children}
    </WizardContext.Provider>
  );
};

export const useWizard = () => {
  const context = useContext(WizardContext);

  if (!context) {
    throw new Error('You must use WizardContext inside WizardContextProvider.');
  }

  return context;
};
