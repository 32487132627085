import React, { FC, useMemo } from 'react';
import { LoadingScreen } from '../LoadingScreen';
import { TableLayout } from '../shared/TableLayout';
import { useProcedures } from '../../data-hooks/procedures';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { ProcedureName } from '../../types/procedures';
import { WizardType } from '../dashboard/DashboardMain';

enum TableHeaders {
  TYPE = 'Вид',
  DATE = 'Дата',
  DETAILS = 'Детайли'
}

enum TableAccessors {
  TYPE = 'type',
  DATE = 'date',
  DETAILS = 'details'
}

interface Props {
  type: any;
}

export const ProceduresTableRender: FC<Props> = ({ type }: Props) => {
  const { patientId } = useParams<{ [patientId: string]: string }>();
  const { user } = useAuth();

  const determineProcedureType = (item: any) => {
    switch (item.type) {
      case WizardType.PHOTO:
        return ProcedureName.PHOTO;
      case WizardType.EXTRACTION:
        return ProcedureName.EXTRACTION;
      case WizardType.ENDODONTIA:
        return ProcedureName.ENDODONTIA;
      case WizardType.ORAL_HYGIENE:
        return ProcedureName.ORAL_HYGIENE;
      case WizardType.PROTETIKA:
        return ProcedureName.PROTETIKA;
      case WizardType.SURGERY:
        return ProcedureName.SURGERY;
      case WizardType.PERIODONTAL:
        return ProcedureName.PERIODONTAL;
      case WizardType.IMPLANTS:
        return ProcedureName.IMPLANTS;
      case WizardType.PEDIATRIC:
        return ProcedureName.PEDIATRIC;
      default:
        return 'Процедура';
    }
  };

  const { userData, isLoading } = useProcedures(user?.uid as string, patientId);

  const procedureData = useMemo(() => {
    if (isLoading || !userData) {
      return [];
    }

    return userData.map(item => ({
      type: determineProcedureType(item),
      date: item.createdAt.toLocaleDateString(),
    }));
  }, [isLoading, userData]);

  const procedureColumns: any = React.useMemo(
    () => [
      {
        Header: TableHeaders.TYPE,
        accessor: TableAccessors.TYPE,
      },
      {
        Header: TableHeaders.DATE,
        accessor: TableAccessors.DATE,
      },
    ],
    []
  );

  if (isLoading) {
    return <LoadingScreen/>;
  }

  return (
    <>
      <TableLayout
        columns={procedureColumns}
        data={procedureData}
        type={type}
      />
    </>
  );
};
