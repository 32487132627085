import React, { FC } from 'react';
import { useAsyncDebounce } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { PageType } from './TableMenu';

interface GlobalFilterProps {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
  pageType: PageType;
}

export const GlobalFilter: FC<GlobalFilterProps> = (props: GlobalFilterProps) => {
  const {
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    pageType
  } = props;

  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="search-form mg-t-20 mg-sm-t-0">
      <input
        type="search"
        className="form-control"
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count}${' '}${
          pageType === PageType.DOCTORS 
          ? 'лекари' 
          : pageType === PageType.PATIENTS 
            ? ' пациенти'
            : 'процедури'
        }...`}
      />
      <button className="btn" type="button">
        <FontAwesomeIcon icon={faSearch}/>
      </button>
    </div>
  );
}
