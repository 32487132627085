import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ProcedureName } from '../../types/procedures';
import { useWizard } from '../../contexts/WizardContext';
import { WizardType } from '../dashboard/DashboardMain';


export const CreateProcedure: FC = (): JSX.Element => {
  const {
    updateProcedureType
  } = useWizard();

  const { patientId } = useParams<{ [patientId: string]: string }>();
  const history = useHistory();

  const pickWizard = (type: any) => {
    history.replace(`/patients/${patientId}/procedure/${type}/0`);
    updateProcedureType(type);
  };

  return (
    <>
      <div className="content content-fixed dashboard-content">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="row row-md mg-b-25 justify-content-center">
                <div className="list-group mt-5">
                  <div
                    onClick={() => pickWizard(WizardType.EXTRACTION)}
                    className="list-group-item list-group-item-action">
                    {ProcedureName.EXTRACTION}
                  </div>
                  <div
                    onClick={() => pickWizard(WizardType.SURGERY)}
                    className="list-group-item list-group-item-action">
                    {ProcedureName.SURGERY}
                  </div>
                  <div
                    onClick={() => pickWizard(WizardType.ENDODONTIA)}
                    className="list-group-item list-group-item-action">
                    {ProcedureName.ENDODONTIA}
                  </div>
                  <div
                    onClick={() => pickWizard(WizardType.IMPLANTS)}
                    className="list-group-item list-group-item-action">
                    {ProcedureName.IMPLANTS}
                  </div>
                  <div
                    onClick={() => pickWizard(WizardType.PERIODONTAL)}
                    className="list-group-item list-group-item-action">
                    {ProcedureName.PERIODONTAL}
                  </div>
                  <div
                    onClick={() => pickWizard(WizardType.ORAL_HYGIENE)}
                    className="list-group-item list-group-item-action">
                    {ProcedureName.ORAL_HYGIENE}
                  </div>
                  <div
                    onClick={() => pickWizard(WizardType.PROTETIKA)}
                    className="list-group-item list-group-item-action">
                    {ProcedureName.PROTETIKA}
                  </div>
                  <div
                    onClick={() => pickWizard(WizardType.PHOTO)}
                    className="list-group-item list-group-item-action">
                    {ProcedureName.PHOTO}
                  </div>
                  <div
                    onClick={() => pickWizard(WizardType.PEDIATRIC)}
                    className="list-group-item list-group-item-action">
                    {ProcedureName.PEDIATRIC}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
