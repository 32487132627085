import React, { FC } from 'react';
import { ModalInfo } from '../shared/ModalInfo';

export const Footer: FC = (): JSX.Element => (
  <footer className="footer">
    <div>
      <span>&copy; 2021 InformDental </span>
    </div>
    <div>
      <nav className="nav">
        <a href="#modalInfo" className="text-white text-decoration-none" data-toggle="modal">
          <div className="nav-link">
            Лицензи
          </div>
        </a>
      </nav>
    </div>
    <ModalInfo header='Лицензи' text='текст'/>
  </footer>
);
