import React, { FC, useMemo } from 'react';
import { LoadingScreen } from '../LoadingScreen';
import { usePatients } from '../../data-hooks/patients';
import { TableLayout } from '../shared/TableLayout';
import { PageType } from '../shared/TableMenu';
import { useAuth } from '../../contexts/AuthContext';

enum TableHeaders {
  FULL_NAME = 'Име',
  PIN = 'ЕГН',
}

enum TableAccessors {
  FULL_NAME = 'fullName',
  PIN = 'pin',
}

export const PatientsTableRender: FC = () => {
  const {user} = useAuth();
  const userId = user?.uid as string;
  const { userData, isLoading } = usePatients(userId);

  const patientData = useMemo(() => {
    if (isLoading || !userData) {
      return [];
    }

    return userData.map(item => ({
      fullName: item.fullName,
      pin: item.personalId,
      documents: 'Документи'
    }));
  }, [isLoading, userData]);

  const patientsColumns: any = React.useMemo(
    () => [
      {
        Header: TableHeaders.FULL_NAME,
        accessor: TableAccessors.FULL_NAME,
      },
      {
        Header: TableHeaders.PIN,
        accessor: TableAccessors.PIN,
      },
    ],
    []
  );

  if (isLoading) {
    return <LoadingScreen/>;
  }

  return (
    <>
      <TableLayout
        columns={patientsColumns}
        data={patientData}
        type={PageType.PATIENTS}
      />
    </>
  );
};
