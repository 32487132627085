import useSWR, { mutate as globalMutate } from 'swr';
import {
  createDoctor,
  deleteDoctor,
  getDoctor,
  getDoctors,
  updateDoctor
} from '../firebase/firestore/doctors';
import { DOCTORS } from '../constants/firebase/collections';
import { DoctorData, OptionalDoctorData } from '../types/doctors';

const db = {
  createDoctor,
  deleteDoctor,
  getDoctor,
  getDoctors,
  updateDoctor
}

export const useDoctors = () => {
  const { data, error } = useSWR(`${DOCTORS}`, () => db.getDoctors());

  return {
    doctors: data,
    isLoading: !error && !data,
    error
  };
};

export const useDoctor = (uid: string) => {
  const { data, error, mutate } = useSWR(`/${DOCTORS}/${uid}`, () =>  db.getDoctor(uid));

  return {
    doctor: data,
    isLoading: !error && !data,
    error,
    doctorUpdate: async (newData: OptionalDoctorData) => {
      await  db.updateDoctor(uid, newData);
      await mutate();
    },
    doctorDelete: async () => {
      await  db.deleteDoctor(uid);
      await mutate();
    }
  };
};

export const doctorCreate = async (data: DoctorData) => {
  await  db.createDoctor(data.email, data);
  await globalMutate(`${DOCTORS}`);
};
