import React, { FC } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useForm } from 'react-hook-form';
import { LoadingScreen } from '../LoadingScreen';
import { useHistory } from 'react-router-dom';
import { errorMessage } from '../../errors/errors';

interface UpdatePasswordValues {
  password: string;
  // confirm: string;
}

export const UpdatePassword: FC = (): JSX.Element => {
  const { user, isLoading, error } = useAuth();
  const { register, handleSubmit, formState: {errors} } = useForm<UpdatePasswordValues>();
  const history = useHistory();

  const onSubmit = async (values: UpdatePasswordValues) => {
    if (!user) {
      return;
    }

    await user.updatePassword(values.password as string);
    history.push('/dashboard');
  }

  if (isLoading) {
    return <LoadingScreen/>
  }

  if (error) {
    return (
      <div>{error.message}</div>
    );
  }

  return (
    <div className="content content-fixed content-auth">
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div className="sign-wrapper">
            <div className="wd-100p">
              <h3 className="tx-color-01 mg-b-5">Вход</h3>
              <p className="tx-color-03 tx-16 mg-b-40">Добре дошли! Моля, създайте парола за своя акаунт</p>

              <div className='mt-3 text-center'>
                <p className='text-danger'>{error ? errorMessage(error) : ''}</p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="password">Парола</label>
                  <input
                    id="password"
                    type="password"
                    {...register('password', { required: 'Моля въведете парола' })}
                    className="form-control"
                    placeholder="Въведете парола"
                  />
                  {errors.password && <p className='text-danger'>Това поле е задължително!</p>}
                </div>
                {/*<div className="form-group">*/}
                {/*  <label htmlFor="confirm">Потвърди Парола</label>*/}
                {/*  <input*/}
                {/*    name="confirm"*/}
                {/*    id="confirm"*/}
                {/*    type="password"*/}
                {/*    ref={register({ required: true })}*/}
                {/*    className="form-control"*/}
                {/*    placeholder="Потвърдете парола"*/}
                {/*  />*/}
                {/*  {errors.confirm && <p className='text-danger'>Това поле е задължително!</p>}*/}
                {/*</div>*/}
                <button type="submit" className="btn btn-brand-02 btn-block" disabled={isLoading}>Продължи</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
