import React, { FC, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { PatientHealthData } from '../../types/patients';
import { ProcedureType } from '../../types/procedures';
import {
  CheckboxWithTextField,
  FieldTypes,
  FormField,
  FormFieldName,
  Paragraph,
  SingleSelectField
} from '../../types/wizard/form-fields';
import { ErrorMessage } from '@hookform/error-message';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import SignatureCanvas from 'react-signature-canvas';
import { LoadingSpinner } from '../LoadingSpinner';

interface WizardFormProps {
  currentStep: any;
  setCurrentStep: any;
  wizardStep: any;
  data: any;
  patientData: any;
  onSubmit: any;
  wizardType: any;
  prevSteps: any;
  loading: any;
  isFinal?: any;
  formDisabled?: boolean;
}

export const WizardForm: FC<WizardFormProps> = (
  {
    currentStep,
    setCurrentStep,
    wizardStep,
    data,
    patientData,
    onSubmit,
    wizardType,
    prevSteps,
    loading,
    isFinal,
    formDisabled
  }: WizardFormProps
): JSX.Element => {
  const { step } = useParams<{ step: string; procedureId: string; }>();
  const {
    register,
    handleSubmit,
    control,
    formState,
  } = useForm<Partial<PatientHealthData & ProcedureType>>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      ...data,
      date: data?.date ? new Date(data.date) : undefined
    }
  });

  const errors = formState.errors;
  const history = useHistory();

  // Patient Signature
  const sigRef = useRef(null);

  const clearCanvas = () => {
    // @ts-ignore
    return sigRef.current.clear();
  };

  // Doctor Signature
  const sigRefDoc = useRef(null);

  const clearCanvasDoc = () => {
    // @ts-ignore
    return sigRefDoc.current.clear();
  };

  // Witness Signature
  const sigRefWit = useRef(null);

  const clearCanvasWit = () => {
    // @ts-ignore
    return sigRefDoc.current.clear();
  };

  const formatIntoPng = (ref: any) => {
    if (ref.current) {
      return ref.current.toDataURL();
    }
  };

  // SIGNATURE PREVIEW
  const [patientSignatureTrim, setPatientSignatureTrim] = useState(null);
  const [doctorSignatureTrim, setDoctorSignatureTrim] = useState(null);
  const [witnessSignatureTrim, setWitnessSignatureTrim] = useState(null);

  const trimPatientSignature = (ref: any) => {
    if (ref.current) {
      setPatientSignatureTrim(ref.current.toDataURL('image/png'));
    }
  };

  const trimDocSignature = (ref: any) => {
    if (ref.current) {
      setDoctorSignatureTrim(ref.current.toDataURL('image/png'));
    }
  };

  const trimWitnessSignature = (ref: any) => {
    if (ref.current) {
      setWitnessSignatureTrim(ref.current.toDataURL('image/png'));
    }
  };

  const renderField = (field: FormField, i: number) => {

    switch (field.type) {
      case FieldTypes.TEXT:
        return (
          <div key={`step-field-${i}`} className="form-group">
            <label htmlFor={`text-input-field-${field.name}`} className="d-block">{field.label}</label>
            <input
              {...register(field.name as FormFieldName)}
              id={`text-input-field-${field.name}`}
              type="text"
              className="form-control"
              disabled={formDisabled}
              placeholder={field.placeholder}
              defaultValue={
                field.name === 'fullName'
                  ? patientData.fullName
                  : field.name === 'personalId'
                  ? patientData.personalId
                  : ''
              }
            />
            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.TEXT_REQUIRED:
        return (
          <div key={`required-step-field-${i}`} className="form-group">
            <label htmlFor={`required-text-input-field-${field.name}`} className="d-block">{field.label}</label>
            <input
              {...register(field.name as FormFieldName, { required: 'Това поле е задължително' })}
              id={`required-text-input-field-${field.name}`}
              type="text"
              className="form-control"
              disabled={formDisabled}
              placeholder={field.placeholder}
              defaultValue={
                field.name === 'fullName'
                  ? patientData.fullName
                  : field.name === 'personalId'
                  ? patientData.personalId
                  : ''
              }
            />
            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.PARAGRAPH:
        return (
          <div key={`paragraph-${i}`} dangerouslySetInnerHTML={{ __html: (field as Paragraph).text }}/>
        );

      case FieldTypes.CHECKBOX_REQUIRED:
        return (
          <div key={`required-checkbox-input-${i}`} className="custom-control custom-checkbox py-3">
            <input
              {...register(field.name as FormFieldName, { required: 'Моля потвърдете' })}
              id={`switch-input-${field.name}`}
              type="checkbox"
              className="custom-control-input"
              disabled={formDisabled}
            />
            <label
              className="custom-control-label"
              htmlFor={`switch-input-${field.name}`}
              dangerouslySetInnerHTML={{ __html: field.label }}
            />
            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.CHECKBOX:
        return (
          <div key={`checkbox-input-${i}`} className="custom-control custom-switch py-3">
            <input
              {...register(field.name as FormFieldName)}
              name={field.name}
              id={`switch-input-${field.name}`}
              type="checkbox"
              className="custom-control-input float-right"
              disabled={formDisabled}
            />
            <label
              className="custom-control-label"
              htmlFor={`switch-input-${field.name}`}
              dangerouslySetInnerHTML={{ __html: field.label }}
            />
            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p>{message}</p>}
            />
          </div>
        );

      case FieldTypes.CHECKBOX_AND_TEXT:
        return (
          <div key={`checkbox-input-${field.name}-text-${i}`} className="custom-control custom-switch py-3">
            <input
              {...register(field.name as FormFieldName)}
              name={field.name}
              id={`switch-input-with-text-${field.name}`}
              type="checkbox"
              className="custom-control-input float-right"
              disabled={formDisabled}
            />
            <label
              htmlFor={`switch-input-with-text-${field.name}`}
              className="custom-control-label"
            >
              {field.label}
            </label>

            <div className="mt-2">
              <label
                htmlFor={`if-yes-input-${(field as CheckboxWithTextField).inputName}`}
              >
                {(field as CheckboxWithTextField).inputLabel}
              </label>
              <input
                {...register((field as CheckboxWithTextField).inputName as FormFieldName)}
                id={`if-yes-input-${(field as CheckboxWithTextField).inputName}`}
                type="text"
                className="form-control"
                disabled={formDisabled}
              />
            </div>

            {(field as CheckboxWithTextField).inputNameTwo !== undefined
              ? <div className="mt-2">
                <label
                  htmlFor={`if-yes-input-${(field as CheckboxWithTextField).inputNameTwo}`}
                >
                  {(field as CheckboxWithTextField).inputLabelTwo}
                </label>
                <input
                  {...register((field as CheckboxWithTextField).inputNameTwo as FormFieldName)}
                  id={`if-yes-input-${(field as CheckboxWithTextField).inputNameTwo}`}
                  type="text"
                  className="form-control"
                />
              </div>
              : (field as CheckboxWithTextField).inputLabelTwo !== undefined ? <label className="pt-1">
                  {(field as CheckboxWithTextField).inputLabelTwo}
                </label>
                : null
            }
          </div>
        );

      case FieldTypes.CHECKBOX_AND_TEXT_REQUIRED:
        return (
          <div key={`checkbox-input-${field.name}-text-${i}`} className="custom-control custom-switch py-3">
            <input
              {...register(field.name as FormFieldName)}
              name={field.name}
              id={`switch-input-with-text-${field.name}`}
              type="checkbox"
              className="custom-control-input float-right"
              disabled={formDisabled}
            />
            <label
              htmlFor={`switch-input-with-text-${field.name}`}
              className="custom-control-label"
            >
              {field.label}
            </label>

            <div className="mt-2">
              <label
                htmlFor={`if-yes-input-${(field as CheckboxWithTextField).inputName}`}
              >
                {(field as CheckboxWithTextField).inputLabel}
              </label>
              <input
                {...register((field as CheckboxWithTextField).inputName as FormFieldName, { required: `Моля попълнете. (При негативен отговор попълнете 'НЕ')` })}
                id={`if-yes-input-${(field as CheckboxWithTextField).inputName}`}
                type="text"
                className="form-control"
                disabled={formDisabled}
              />
            </div>

            {(field as CheckboxWithTextField).inputNameTwo !== undefined
              ? <div className="mt-2">
                <label
                  htmlFor={`if-yes-input-${(field as CheckboxWithTextField).inputNameTwo}`}
                >
                  {(field as CheckboxWithTextField).inputLabelTwo}
                </label>
                <input
                  {...register((field as CheckboxWithTextField).inputNameTwo as FormFieldName, { required: 'Моля потвърдете' })}
                  id={`if-yes-input-${(field as CheckboxWithTextField).inputNameTwo}`}
                  type="text"
                  className="form-control"
                />
              </div>
              : (field as CheckboxWithTextField).inputLabelTwo !== undefined ? <label className="pt-1">
                  {(field as CheckboxWithTextField).inputLabelTwo}
                </label>
                : null
            }
            <ErrorMessage
              errors={errors}
              name={(field as CheckboxWithTextField).inputName as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.TEXTAREA:
        return (
          <div key={`textarea-${i}`} className="py-3">
            <label htmlFor={`textarea-field-${field.name}`}>{field.label}</label>
            <textarea
              {...register(field.name as FormFieldName)}
              name={field.name}
              className="form-control"
              id={`textarea-field-${field.name}`}
              placeholder="Textarea"
              disabled={formDisabled}
            />
            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.TEXTAREA_REQUIRED:
        return (
          <div key={`required-textarea-${i}`} className="py-3">
            <label htmlFor={`required-textarea-field-${field.name}`}>{field.label}</label>
            <textarea
              {...register(field.name as FormFieldName, { required: 'моля въведете текст' })}
              name={field.name}
              className="form-control"
              id={`required-textarea-field-${field.name}`}
              placeholder="Textarea"
              disabled={formDisabled}
            />
            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.SINGLE_SELECT:
        return (
          <div key={`single-select-${field.name}`}>
            <label
              className="d-block"
              htmlFor={`select-${field.name}`}
            >
              {field.label}
            </label>
            <Controller
              name={field.name as FormFieldName}
              key={`select-input-${i}`}
              control={control}
              rules={{ required: 'Моля изберете една от опциите.' }}
              render={(props) => (
                // @ts-ignore
                <Select
                  {...props.field}
                  options={(field as SingleSelectField).options}
                  className="select2-dropdown"
                  id={`select-${field.name}`}
                  classNamePrefix="select"
                  disabled={formDisabled}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.MULTI_SELECT:
        return (
          <div key={`multi-select-${field.name}`}>
            <label
              className="d-block"
              htmlFor={`select-${field.name}`}
            >
              {field.label}
            </label>
            <Controller
              name={field.name as FormFieldName}
              key={`multi-select-input-${i}`}
              control={control}
              rules={{ required: 'Моля изберете една от опциите.' }}
              render={(props) => (
                // @ts-ignore
                <Select
                  {...props.field}
                  isMulti={true}
                  options={(field as SingleSelectField).options}
                  className="select2-dropdown"
                  id={`select-${field.name}`}
                  classNamePrefix="select"
                  disabled={formDisabled}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.DATEPICKER:
        debugger;
        return (
          <div key={`datepicker-${field.name}-${i}`} className="mt-3 px-2">
            <label className="msg-box mr-3">Дата</label>
            <Controller
              control={control}
              key={`date-input-${i}`}
              name={field.name as FormFieldName}
              rules={{ required: 'Моля въведете дата' }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className="form-control"
                  // dateFormat="dd/mm/yyyy"
                  placeholderText="Изберете дата"
                  disabled={formDisabled}
                  onChange={onChange}
                  onBlur={onBlur}
                  // onChange={(e) => props.field.onChange(e)}
                  selected={value as Date}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.SIGNATURE:
        return (
          <div key={`signature-${field.name}`} className="signature-input-container">
            <div className="canvas-top px-2 py-2">
              <label className="msg-box">Подпис пациент:</label>
              {!isFinal &&
              <div className="clear-canvas btn btn-danger float-right" onClick={clearCanvas}>
                Изчисти
              </div>}
            </div>
            {!isFinal &&
            <div className="sign-box border border-secondary rounded-10">
              <Controller
                name={field.name as FormFieldName}
                control={control}
                rules={{ required: 'Моля подпишете се.' }}
                render={(props) => (
                  <SignatureCanvas
                    {...props.field}
                    canvasProps={{ className: 'sigCanvas' }}
                    ref={sigRef}
                    onEnd={() => {
                      trimPatientSignature(sigRef);
                      props.field.onChange(formatIntoPng(sigRef));
                    }}
                  />
                )}
              />
            </div>
            }
            {isFinal
              ? <img
                className="img-fluid"
                src={data.patientSignature || patientSignatureTrim}
                alt="patient-signature"
              />
              : null}
            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.SIGNATURE_DOCTOR:
        return (
          <div key={`doctor-signature-${field.name}`} className="signature-input-container">
            <div className="canvas-top px-2 py-2">
              <label className="msg-box">Подпис лекар:</label>
              {!isFinal &&
              <div className="clear-canvas btn btn-danger float-right" onClick={clearCanvasDoc}>
                Изчисти
              </div>}
            </div>

            {!isFinal &&
            <div className="sign-box border border-secondary rounded-10">
              <Controller
                name={field.name as FormFieldName}
                control={control}
                rules={{ required: 'Моля подпишете се.' }}
                render={(props) => (
                  <SignatureCanvas
                    {...props.field}
                    canvasProps={{ className: 'sigCanvas' }}
                    ref={sigRefDoc}
                    onEnd={() => {
                      trimDocSignature(sigRefDoc);
                      props.field.onChange(formatIntoPng(sigRefDoc));
                    }}
                  />
                )}
              />
            </div>}

            {isFinal
              ?
              <div className="media">
                <img
                  className="img-fluid"
                  src={data.doctorSignature || doctorSignatureTrim}
                  alt="doctor-signature"
                />
              </div>
              : null}

            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      case FieldTypes.SIGNATURE_WITNESS:
        return (
          <div key={`witness-signature-${field.name}`} className="signature-input-container">
            <div className="canvas-top px-2 py-2">
              <label className="msg-box">Подпис свидетел:</label>
              {!isFinal &&
              <div className="clear-canvas btn btn-danger float-right" onClick={clearCanvasWit}>
                Изчисти
              </div>
              }
            </div>

            {!isFinal &&
            <div className="sign-box border border-secondary rounded-10">
              <Controller
                name={field.name as FormFieldName}
                control={control}
                render={(props) => (
                  <SignatureCanvas
                    {...props.field}
                    canvasProps={{ className: 'sigCanvas' }}
                    ref={sigRefWit}
                    onEnd={() => {
                      trimWitnessSignature(sigRefWit);
                      props.field.onChange(formatIntoPng(sigRefWit));
                    }}
                  />
                )}
              />
            </div>}

            {isFinal
              ? <img
                className="img-fluid"
                src={data.witnessSignature || witnessSignatureTrim}
                alt="patient-signature"
              />
              : null}

            <ErrorMessage
              errors={errors}
              name={field.name as FormFieldName}
              render={({ message }) => <p className="text-danger">{message}</p>}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <form key={`form-key-${currentStep}-${wizardType}`} onSubmit={handleSubmit(onSubmit)}>

      {wizardStep.fields.map(renderField)}

      {!isFinal && <div className="">
        <div className="row btn-group-lg">

          {currentStep !== 0 &&
          <div className="col-sm-4">
            {/*// BACK BUTTON*/}
            <button
              className="btn btn-block btn-info mb-2 mb-lg-0"
              onClick={() => {
                currentStep !== 0 ? setCurrentStep(currentStep - 1) : setCurrentStep(0);
                history.push(`${Number(step) - 1}`);
                window.scrollTo(0, 0);
              }}
            >
              Назад
            </button>
          </div>}

          <div className="col-sm-4 offset-sm-4">
            {currentStep === wizardType.steps.length - 1
              ? // FINISH BUTTON
              <button
                className="btn btn-block btn-primary"
                onClick={() => {
                  handleSubmit(onSubmit);
                }}>
                {loading
                  ? (<LoadingSpinner dark={true}/>)
                  : 'Приключи'}
              </button>

              : // FORWARD BUTTON
              <button
                className="btn btn-block btn-primary"
                onClick={() => {
                  if (prevSteps.includes(currentStep)) {
                    setCurrentStep(currentStep + 1);
                    history.push(`${currentStep + 1}`);
                    window.scrollTo(0, 0);
                  } else {
                    handleSubmit(onSubmit);
                  }
                }}
              >Напред
              </button>}
          </div>
        </div>
      </div>}
    </form>
  );
};
