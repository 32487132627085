const VERSION = '2.1234567';
const VERSION_KEY = 'zppv';
const KEY = 'zpp_wizard_state';

export const getWizardState = () => {
  const version = localStorage.getItem(VERSION_KEY);

  if (!version) {
    return null;
  }

  if (version !== VERSION) {
    return null;
  }

  const state = localStorage.getItem(KEY);

  if (!state) {
    return null;
  }

  try {
    return JSON.parse(state);
  } catch (e) {
    return null;
  }
};

export const setWizardState = (state: any) => {
  localStorage.setItem(KEY, JSON.stringify(state));
  localStorage.setItem(VERSION_KEY, VERSION);
};
