import React, { FC } from 'react';
import { imagePreview } from '../../helpers/image-preview';

interface ImageInputProps {
  register: any;
  currentPhoto: any;
  imageInput: any;
  setCurrentPhoto: any;
  defaultImage?: {
    image: any;
  }
}

export const ImageInput: FC<ImageInputProps> = ({
                                                  register,
                                                  currentPhoto,
                                                  imageInput,
                                                  setCurrentPhoto,
                                                }) => {
  return (
    <div className="">
      <div className="w-100">
        {
          currentPhoto.image === 'image'
            ? <img
              src='/assets/img/largo.png'
              className="avatar-xxl d-flex m-auto"
              alt="default-logo"
            />
            : <img
              src={currentPhoto.image}
              className="img-fluid"
              alt="logo"
            />}

        <div className='d-flex py-4'>
          {currentPhoto.image === 'image'
            ?
            <div className="btn-block btn-lg btn-primary text-center" onClick={() => {
              imageInput.current?.click();
            }}>
              Качете лого
            </div>
            : <div className="btn-lg btn-primary mr-1 w-50 text-center" onClick={() => {
              imageInput.current?.click();
            }}>
              Смяна
            </div>}

          <input
            {...register('image')}
            type="file"
            id="fileUpload"
            ref={imageInput}
            className="upload-input"
            style={{ display: 'none' }}
            onChange={(e) => imagePreview(e, currentPhoto, setCurrentPhoto)}
          />

          {currentPhoto.image === 'image'
            ? null
            : <div
              onClick={() => {
                setCurrentPhoto('image');
                imageInput.current?.remove();
              }}
              className="btn-lg btn-danger w-50 ml-1 text-center">Премахване</div>}
        </div>
      </div>
    </div>
  );
};
