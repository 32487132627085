import React, { FC } from 'react';
import { PatientsTableRender } from './PatientsTableRender';

export const PatientsTable: FC = (): JSX.Element => {

  return (
    <div className='patients-content'>
      <PatientsTableRender/>
    </div>
  );
};
