import React, { FC } from 'react';

interface ModalInfoProps {
  header: string;
  text: string;
}

export const ModalInfo: FC<ModalInfoProps> = ({ header, text }: ModalInfoProps): JSX.Element => {

  return (
    <div className="modal fade" id="modalInfo" tabIndex={-1} role="dialog"
         aria-labelledby="modalInfo"
         aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content tx-14">
          <div className="modal-header">
            <h6 className="modal-title" id="modalInfo">{header}</h6>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h2>{header}</h2>
            <p className="mg-b-0">{text}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-primary btn-lg tx-13">Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
