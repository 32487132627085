import React, { FC } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { useDoctor } from '../../data-hooks/doctors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { LoadingScreen } from '../LoadingScreen';

export const DoctorProfile: FC = (): JSX.Element => {
  const { doctorId } = useParams<{ [doctorId: string]: string }>();
  const { doctor, isLoading } = useDoctor(doctorId);

  if (isLoading || !doctor) {
    return <LoadingScreen/>;
  }

  return (
    <div className="doctors-content">
      <div className="content content-fixed">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <NavLink to="/">DentDoc</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Лекарски профил</li>
                </ol>
              </nav>
              <h4 className="mg-b-0">Профил</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="media justify-content-center ht-100p">
          <div className="sign-wrapper">
            <div className="pd-t-20 wd-100p">
              <h4 className="tx-color-01 mg-b-5">Профил</h4>
              <p className="tx-color-03 tx-16 mg-b-40">От тук можете да конфигурирате данните в своя профил.</p>
              <div className="form-group">
                <div className='mb-4 d-flex justify-content-center'>
                <img
                  className="avatar-xl rounded-20"
                  src={`${doctor?.image || '/assets/img/largo.png'}`}
                  alt="logo"
                  width="200"
                  height="200"
                />
                </div>

                <div className="form-group">
                  <label htmlFor="first-name-input">Име</label>
                  <input
                    id="first-name-input"
                    type="text"
                    disabled={true}
                    className="form-control"
                    defaultValue={doctor.firstName || ''}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="last-name-input">Фамилия</label>
                  <input
                    id="last-name-input"
                    type="text"
                    disabled={true}
                    className="form-control"
                    defaultValue={doctor?.lastName || ''}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email-input">Имейл адрес</label>
                  <input
                    id="email-name-input"
                    type="text"
                    disabled={true}
                    className="form-control"
                    defaultValue={doctor?.email || ''}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phone-input">Телефонен номер</label>
                  <input
                    id="phone-input"
                    type="text"
                    disabled={true}
                    className="form-control"
                    defaultValue={doctor?.phoneNumber || 'Няма запазен телефон'}
                  />
                </div>
                <Link
                  to={`/doctors/${doctorId}/edit`}>
                  <button type="button" className="btn btn-block btn-primary btn-icon">
                    <FontAwesomeIcon icon={faPen} className="mr-2"/>
                    Редактирай
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
