import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { faUserCircle, faUserEdit, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../contexts/AuthContext';

export const NavProfile: FC = (): JSX.Element => {
  const { user, signOut } = useAuth();

  return (
    <div className="dropdown dropdown-profile navbar-profile-dropdown">
      <span className="dropdown-link" data-toggle="dropdown" data-display="static">
        <div className="avatar avatar-sm">
          <FontAwesomeIcon icon={faUserCircle} className='profile-icon' />
        </div>
      </span>
      <div className="dropdown-menu dropdown-menu-right">
        <Link to={`/doctors/${user?.uid}/profile`} className="dropdown-item">
          <FontAwesomeIcon icon={faUserEdit} className='' />
          Профил
        </Link>
        <span className="dropdown-item" onClick={signOut}>
          <FontAwesomeIcon icon={faSignOutAlt} className='' />
          Изход
        </span>
      </div>
    </div>
  );
};
