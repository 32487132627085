import React, { FC, useCallback, useState } from 'react';
import {
  extractionWizard,
  surgeryWizard,
  endodontWizard,
  implantsWizard,
  periodontalWizard,
  oralHygieneWizard,
  protetikaWizard,
  photoWizard,
  pediatricDentistryWizard
} from '../../contexts/wizard-data';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useWizard } from '../../contexts/WizardContext';
import 'react-datepicker/dist/react-datepicker.css';
import { WizardForm } from './WizardForm';
import { AppRoutes } from '../../constants/routes';
import { useDoctor } from '../../data-hooks/doctors';
import { useAuth } from '../../contexts/AuthContext';

export const Wizard: FC = (): JSX.Element => {
  const {
    updateStepData,
    data,
    loading,
    patientData
  } = useWizard();

  const { step, procedureId } = useParams<{ step: string; procedureId: string; }>();
  const [currentStep, setCurrentStep] = useState<number>(Number(step));
  const [wizardPreview, setWizardPreview] = useState<boolean>(false);
  const [prevSteps,] = useState<any>([]);
  const { user } = useAuth();

  const { doctor } = useDoctor(user?.uid as string);

  const history = useHistory();

  // Determine which wizard to start
  let wizardStep: any;
  let wizardType: any;
  if (procedureId === extractionWizard.slug) {
    wizardType = extractionWizard;
    wizardStep = extractionWizard.steps[currentStep];
  } else if (procedureId === surgeryWizard.slug) {
    wizardType = surgeryWizard;
    wizardStep = surgeryWizard.steps[currentStep];
  } else if (procedureId === endodontWizard.slug) {
    wizardType = endodontWizard;
    wizardStep = endodontWizard.steps[currentStep];
  } else if (procedureId === implantsWizard.slug) {
    wizardType = implantsWizard;
    wizardStep = implantsWizard.steps[currentStep];
  } else if (procedureId === periodontalWizard.slug) {
    wizardType = periodontalWizard;
    wizardStep = periodontalWizard.steps[currentStep];
  } else if (procedureId === oralHygieneWizard.slug) {
    wizardType = oralHygieneWizard;
    wizardStep = oralHygieneWizard.steps[currentStep];
  } else if (procedureId === protetikaWizard.slug) {
    wizardType = protetikaWizard;
    wizardStep = protetikaWizard.steps[currentStep];
  } else if (procedureId === photoWizard.slug) {
    wizardType = photoWizard;
    wizardStep = photoWizard.steps[currentStep];
  } else {
    wizardType = pediatricDentistryWizard;
    wizardStep = pediatricDentistryWizard.steps[currentStep];
  }

  // Update Wizard Data on each step
  const onSubmit = useCallback(async (stepData: any) => { // TODO ProcedureType and remove fullName and Id from the wizard?

    try {
      updateStepData(stepData);

      if (currentStep === wizardType.steps.length - 1) {
        // history.push('/finish-wizard');
        setWizardPreview(true);
        window.scrollTo({top: 0})
      } else {
        setCurrentStep(currentStep + 1);
        prevSteps.push(currentStep);
        history.push(`${Number(step) + 1}`);
        window.scrollTo(0, 0);
      }

    } catch (e) {
      console.log('error', e);
    }
  }, [currentStep, history, prevSteps, step, updateStepData, wizardType.steps.length]);

  const finalRender = (wizardType: any) => {
    let content = [];
    for (let s of wizardType.steps) {
      content.push(
        <div key={`key-${s.name}-${Math.random()}`}>
          <WizardForm
            currentStep={s}
            setCurrentStep={setCurrentStep}
            patientData={patientData}
            wizardStep={s}
            data={data}
            onSubmit={onSubmit}
            wizardType={wizardType}
            prevSteps={prevSteps}
            loading={loading}
            isFinal={true}
            formDisabled={true}
          />
        </div>
      );
    }
    return content;
  };

  return (
    <div className="content">
      <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
        <div className="row">
          {wizardPreview
            ?
            <div className="col-lg-8 col-md-8">
              <h2>Моля потвърдете, че данните са попълнени правилно, преди да бъдат изпратени на вашият лекар.</h2>
            </div>
            :
            <div className="col-lg-8 col-md-8">
              <ul className="steps steps-vertical position-sticky">
                {wizardType.steps.map((step: any, i: number) => (
                  <li key={`step-${i}`}
                      className={`step-item 
                    ${currentStep === i ? 'active' : ''} 
                    ${prevSteps.includes(i) ?
                        `${currentStep === i ? 'active' : 'complete'}` : ''}
                      `}>
                        <span onClick={() => prevSteps.includes(i) ? setCurrentStep(i) : ''}
                              className="step-link wizard-step-link">
                          <span className="step-number">{i + 1}</span>
                          <span className="step-title">{step.name}</span>
                        </span>
                  </li>
                ))}
              </ul>
            </div>
          }

          <div className="col-lg-4 col-md-4 pt-5 pt-lg-0">
            <img
              className="img-fluid"
              src={`${doctor?.image || '/assets/img/largo.png'}`}
              alt="logo"
              width="500"
              height="600"
            />
            <Link to={AppRoutes.PATIENTS}>
              <button className="btn btn-danger btn-block mt-5">Изход</button>
            </Link>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="row row-md mg-b-25">
              <div className="col">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mg-b-15 tx-inverse divider-text">{wizardStep.name}</h5>
                  </div>
                </div>
                {!wizardPreview &&
                <WizardForm
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  patientData={patientData}
                  wizardStep={wizardStep}
                  data={data}
                  onSubmit={onSubmit}
                  wizardType={wizardType}
                  prevSteps={prevSteps}
                  loading={loading}
                  formDisabled={false}
                />
                }
                {wizardPreview && (
                  <div>
                    {finalRender(wizardType)}
                    <button
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        setWizardPreview(false);
                        history.push(AppRoutes.WIZARD_FINISH);
                      }}>
                      Потвърждаване
                    </button>
                    <button
                      className="btn btn-block btn-info"
                      onClick={() => {
                        setWizardPreview(false);
                      }}>
                      Редактирай
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
