import React, { FC, useCallback, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useAuth } from '../../contexts/AuthContext';
import { errorMessage } from '../../errors/errors';
import { useForm } from 'react-hook-form';
import { LoadingSpinner } from '../LoadingSpinner';
import { useDoctor } from '../../data-hooks/doctors';
import { AppRoutes } from '../../constants/routes';
import { Alert } from '../shared/Alert';
import { useHistory } from 'react-router-dom';

interface ChangePasswordFormValues {
  displayName: string;
  password: string;
  confirmPassword: string;
}

interface InvitationPageProps {
  email: string;
  emailActionLoading: boolean;
}

export const InvitationPage: FC<InvitationPageProps> = ({
                                                          email,
                                                          emailActionLoading
                                                        }: InvitationPageProps): JSX.Element => {
  const {
    error: authError,
    user,
    signOut,
    isLoading: isAuthLoading,
    signInWithEmailAndPassword,
    sendResetPasswordEmail
  } = useAuth();

  const history = useHistory();
  const { doctorUpdate, error: doctorError, isLoading: isDoctorLoading } = useDoctor(user?.uid as string);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitted },
    clearErrors,
    watch
  } = useForm<ChangePasswordFormValues>(
    { mode: 'onBlur' });
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = useCallback(async ({
                                        displayName,
                                        password
                                      }: ChangePasswordFormValues): Promise<string | void> => {
    setIsFormLoading(true);

    if (!user) {
      console.log('No user session.');
      Sentry.captureMessage('No user session on invitation page.');
      setIsFormLoading(false);
      return;
    }

    try {
      console.log('Updating password ...');
      await user.updatePassword(password);

      console.log('Updating profile ...');
      await user.updateProfile({ displayName });

      const fullName = displayName.split(' ');

      await doctorUpdate({
          firstName: fullName[0],
          lastName: fullName[1] || ''
        }
      );

      await signInWithEmailAndPassword({ email, password });
      window.location.href = AppRoutes.PATIENTS;
      // history.replace(AppRoutes.PATIENTS);
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);

      const { code } = e;

      if (code === 'auth/requires-recent-login') {
        await signOut();
      }
      if (code === 'auth/weak-password') {
        return 'Weak password';
      }
      return;
    }

  }, [doctorUpdate, email, signInWithEmailAndPassword, signOut, user]);

  return (
    <div className="content">
      <div className="media justify-content-center ht-100p">
        <div className="sign-wrapper">
          <div className="pd-t-20 wd-100p">
            <h4 className="tx-color-01 mg-b-5">Завършване на регистрация</h4>
            <div className="mt-3 text-center">


              {(isDoctorLoading || isFormLoading || isAuthLoading)
                ?
                <>
                  <LoadingSpinner dark={true}/>
                </>
                :
                <>
                  {authError && !isSubmitted &&
                  <Alert alertType="danger" text={errorMessage(authError)}/>
                  }
                  {authError &&
                  <button className="btn btn-warning" onClick={async () => {
                    await sendResetPasswordEmail({ email: email });
                    Sentry.captureMessage('Doctor requested password reset link after Invitation Page error');
                  }}>Получете нов линк
                  </button>
                  }
                </>
              }


            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <div className="form-group">
                  <label htmlFor="full-name-input">Имена</label>
                  <input
                    id="full-name-input"
                    {...register('displayName', { required: 'Моля въведете потребителско име', minLength: 4 })}
                    type="text"
                    onChange={() => {
                      clearErrors();
                    }}
                    className="form-control"
                    placeholder="Въведете имената си"
                  />
                  {errors.displayName && <p className="text-danger">Това поле е задължително!</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="password-input">Нова парола</label>
                  <input
                    id="password-input"
                    {...register('password', { required: 'Моля въведете потребителско парола' })}
                    type="password"
                    onChange={() => {
                      clearErrors();
                    }}
                    className="form-control"
                    placeholder="Въведете нова парола"
                  />
                  {errors.password && <p className="text-danger">Това поле е задължително!</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="confirm-password-input">Потвърдете новата парола</label>
                  <input
                    id="confirm-password-input"
                    {...register('confirmPassword', {
                      validate: value => value === password.current || 'Новата парола трябва да съвпада!',
                      required: 'Това поле е задължително!'
                    })}
                    type="password"
                    onChange={() => {
                      clearErrors();
                    }}
                    className="form-control"
                    placeholder="Повторете паролата"
                  />
                  {errors.confirmPassword && <p className="text-danger">Паролата не съвпада.</p>}
                </div>
                <button
                  type="submit"
                  disabled={!isDirty || !isValid}
                  className="btn btn-primary btn-block">
                  {isFormLoading || isAuthLoading
                    ? <LoadingSpinner dark={true}/>
                    : 'Вход'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
