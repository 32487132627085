// noinspection SpellCheckingInspection

import React, { FC } from 'react';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { NavLink, useHistory } from 'react-router-dom';
import { DefaultColumnFilter } from './ColumnFilter';
import { TableMenu, PageType } from './TableMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { GlobalFilter } from './GlobalFilter';

interface TableLayoutProps {
  columns: any;
  data: any;
  type: PageType;
}

export const TableLayout: FC<TableLayoutProps> = ({ columns, data, type }: TableLayoutProps) => {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows: any[], id: React.ReactText, filterValue: any) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter, pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter,// useGlobalFilter!
    usePagination
  );

  const getTableLink = (value: any) => {
    if (type === PageType.DOCTORS) {
      return `doctors/${value.id}/profile`;
    }

    if (type === PageType.PATIENTS) {
      return `patients/${value.pin}/profile`;
    }

    if (type === PageType.PROCEDURES) {
      return `profile`;
    }
  };

  const history = useHistory();

  const testLink = (url: any) => {
    history.push(url);
  };

  return (
    <>
      <div className="content content-fixed">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div>
              {(type === PageType.DOCTORS || type === PageType.PATIENTS) &&
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <NavLink to="/">Informdental</NavLink>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >
                    {type === PageType.DOCTORS
                      ? 'Лекари' :
                      type === PageType.PATIENTS
                        ? 'Пациенти'
                        : 'Процедури'}
                  </li>
                </ol>
              </nav>}

              <h4 className="mg-b-0">
                {
                  type === PageType.DOCTORS
                    ? 'Лекари' :
                    type === PageType.PATIENTS
                      ? 'Пациенти'
                      : 'Процедури'}
              </h4>
            </div>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              pageType={type}
            />
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <TableMenu pageType={type} pageSize={pageSize} setPageSize={setPageSize}/>
          <div className="row">
            <div className="col-lg-12">
              <div className="overflow-auto">
                <table className="table table-hover" {...getTableProps()}>
                  <thead className="thead-light">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) => (
                        <th className="wd-20p" {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                  <tr>
                    <th
                      colSpan={visibleColumns.length}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                    </th>
                  </tr>
                  </thead>

                  <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        className='custom-table-row'
                        onClick={() => testLink(getTableLink(row.original) as string)} {...row.getRowProps()}>
                        {row.cells.map((cell, c) => {
                            return (
                              <td {...cell.getCellProps()}>
                                <div>
                                  {cell.render('Cell')}
                                </div>
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  })}
                  </tbody>
                </table>

                <div className="pagination d-flex justify-content-between align-items-center mg-b-0 mt-5">
                  <span>Page{' '}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                  </span>

                  <div className="d-flex">
                    <div className={`page-item ${!canPreviousPage ? 'disabled' : ''}`}>
                      <div
                        className="page-link page-link-icon"
                        onClick={() => gotoPage(0)}
                      >
                        Първа
                      </div>
                    </div>

                    <div className={`page-item ${!canPreviousPage ? 'disabled' : ''}`}>
                      <div
                        className="page-link page-link-icon"
                        onClick={() => previousPage()}
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="plus-icon"
                        />
                      </div>
                    </div>

                    <div className={`page-item ${!canNextPage ? 'disabled' : ''}`}>
                      <div
                        className="page-link page-link-icon"
                        onClick={() => nextPage()}
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="plus-icon"
                        />
                      </div>
                    </div>

                    <div className={`page-item ${!canNextPage ? 'disabled' : ''}`}>
                      <div
                        className="page-link page-link-icon"
                        onClick={() => gotoPage(pageCount - 1)}
                      >
                        Последна
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
