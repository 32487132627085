import React, { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ProcedureName } from '../../types/procedures';

export enum WizardType {
  EXTRACTION = 'extraction-wizard',
  SURGERY = 'surgery-wizard',
  ENDODONTIA = 'endodontia-wizard',
  IMPLANTS = 'implants-wizard',
  PERIODONTAL = 'periodontal-wizard',
  ORAL_HYGIENE = 'oral-hygiene-wizard',
  PROTETIKA = 'protetika-wizard',
  PHOTO = 'photo-wizard',
  PEDIATRIC = 'pediatric-dentistry-wizard',
}

export const DashboardMain: FC = (): JSX.Element => {
  return (
    <div className="content content-fixed dashboard-content">
      <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                <li className="breadcrumb-item">
                  <NavLink to='/'>InformDental</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Дашборд</li>
              </ol>
            </nav>
            <h4 className="mg-b-0 tx-spacing--1">Процедури</h4>
          </div>
        </div>

        <div className="content">
          <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="row row-md mg-b-25">

                  <div className="col-sm-6 col-md-3 py-4">
                    <Link to={`/wizard/${WizardType.EXTRACTION}/1`}>
                      <div className="card document-card text-center">
                        <div className="card-body tx-13">
                          <div>
                            <h6 className='procedure-name'>{ProcedureName.EXTRACTION}</h6>
                            <div className="img-group img-group-sm mg-b-5">
                              <i className="card-img-top ion-md-paper tx-68"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-sm-6 col-md-3 py-4">
                    <Link to={`/wizard/${WizardType.SURGERY}/1`}>
                      <div className="card document-card text-center">
                        <div className="card-body tx-13">
                          <div>
                            <h6>{ProcedureName.SURGERY}</h6>
                            <div className="img-group img-group-sm mg-b-5">
                              <i className="card-img-top ion-md-paper tx-68"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-sm-6 col-md-3 py-4">
                    <Link to={`/wizard/${WizardType.ENDODONTIA}/1`}>
                      <div className="card document-card text-center">
                        <div className="card-body tx-13">
                          <div>
                            <h6>{ProcedureName.ENDODONTIA}</h6>
                            <div className="img-group img-group-sm mg-b-5">
                              <i className="card-img-top ion-md-paper tx-68"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-sm-6 col-md-3 py-4">
                    <Link to={`/wizard/${WizardType.IMPLANTS}/1`}>
                      <div className="card document-card text-center">
                        <div className="card-body tx-13">
                          <div>
                            <h6>{ProcedureName.IMPLANTS}</h6>
                            <div className="img-group img-group-sm mg-b-5">
                              <i className="card-img-top ion-md-paper tx-68"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-sm-6 col-md-3 py-4">
                    <Link to={`/wizard/${WizardType.PERIODONTAL}/1`}>
                      <div className="card document-card text-center">
                        <div className="card-body tx-13">
                          <div>
                            <h6>{ProcedureName.PERIODONTAL}</h6>
                            <div className="img-group img-group-sm mg-b-5">
                              <i className="card-img-top ion-md-paper tx-68"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-sm-6 col-md-3 py-4">
                    <Link to={`/wizard/${WizardType.ORAL_HYGIENE}/1`}>
                      <div className="card document-card text-center">
                        <div className="card-body tx-13">
                          <div>
                            <h6>{ProcedureName.ORAL_HYGIENE}</h6>
                            <div className="img-group img-group-sm mg-b-5">
                              <i className="card-img-top ion-md-paper tx-68"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-sm-6 col-md-3 py-4">
                    <Link to={`/wizard/${WizardType.PROTETIKA}/1`}>
                      <div className="card document-card text-center">
                        <div className="card-body tx-13">
                          <div>
                            <h6>{ProcedureName.PROTETIKA}</h6>
                            <div className="img-group img-group-sm mg-b-5">
                              <i className="card-img-top ion-md-paper tx-68"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-sm-6 col-md-3 py-4">
                    <Link to={`/wizard/${WizardType.PHOTO}/1`}>
                      <div className="card document-card text-center">
                        <div className="card-body tx-13">
                          <div>
                            <h6>{ProcedureName.PHOTO}</h6>
                            <div className="img-group img-group-sm mg-b-5">
                              <i className="card-img-top ion-md-paper tx-68"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-sm-6 col-md-3 py-4">
                    <Link to={`/wizard/${WizardType.PEDIATRIC}/1`}>
                      <div className="card document-card text-center">
                        <div className="card-body tx-13">
                          <div>
                            <h6>{ProcedureName.PEDIATRIC}</h6>
                            <div className="img-group img-group-sm mg-b-5">
                              <i className="card-img-top ion-md-paper tx-68"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
