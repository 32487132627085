import React, { FC } from 'react';
import { DoctorsTableRender } from './DoctorsTableRender';

export const DoctorsTable: FC = (): JSX.Element => {

  return (
    <div className='doctors-content'>
      <DoctorsTableRender/>
    </div>
  );
};
