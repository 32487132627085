const key = 'gahs';

export const signIn = () =>
  localStorage.setItem(key, 's');

export const signOut = () =>
  localStorage.removeItem(key);

export const hasSession = () =>
  !!localStorage.getItem(key);
