import React, { FC } from 'react';

interface AlertProps {
  alertType: string;
  text: string;
}

export const Alert: FC<AlertProps> = ({ alertType, text }: AlertProps) => {
  return (
    <>
      <div className={`alert alert-${alertType}`} role="alert">{text}</div>
    </>
  );
};
