import React, { FC, useRef, useState } from 'react';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { errorMessage } from '../../errors/errors';
import { useAuth } from '../../contexts/AuthContext';
import { useDoctor } from '../../data-hooks/doctors';
import { LoadingScreen } from '../LoadingScreen';
import { ModalDelete } from '../shared/ModalDelete';
import { uploadImage } from '../../firebase/image-upload';
import { LoadingSpinner } from '../LoadingSpinner';
import { ImageInput } from './ImageInput';

interface EditProfileFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  image?: any;
}

export const EditDoctor: FC = (): JSX.Element => {
  const { error } = useAuth();
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm<EditProfileFormValues>();
  const { doctorId } = useParams<{ [doctorId: string]: string }>();
  const { doctor, isLoading, doctorUpdate, doctorDelete } = useDoctor(doctorId);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);


  const [currentPhoto, setCurrentPhoto] = useState({ image: doctor?.image || 'image' });
  console.log(currentPhoto);
  const imageInput = useRef<HTMLInputElement>(null);
  const handleDeleteDoctorProfile = async () => {
    try {
      await doctorDelete();
      history.push('/doctors');
    } catch (e) {
      console.log(e);
      return { e };
    }
  };

  const onSubmit = async (values: EditProfileFormValues) => {
    setFormIsLoading(true);
    let imageFile;

    try {
      if (imageInput.current?.files?.length) {
        imageFile = imageInput.current?.files[0];
        const imageURL = await uploadImage(imageFile);
        await doctorUpdate({ ...values, image: imageURL || doctor?.image });
        console.log('image update - URL:', imageURL);
      } else if (currentPhoto.image === '') {
        await doctorUpdate({ ...values, image: '' });
        console.log('no image file');
      } else {
        await doctorUpdate({ ...values, image: '' });
        console.log('update without image');
      }
      setFormIsLoading(false);
      history.push(`/doctors/${doctorId}/profile`);
    } catch (e) {
      return console.log({ e });
    }
  };

  if (isLoading || !doctor) {
    return <LoadingScreen/>;
  }

  return (
    <div className="doctors-content">
      <div className="content content-fixed">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <NavLink to="/">DentDoc</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Редиктиране на Профил</li>
                </ol>
              </nav>
              <h4 className="mg-b-0">Редиктиране на Профил</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="media justify-content-center ht-100p">
          <div className="sign-wrapper">
            <div className="pd-t-20 wd-100p">
              <div className="mt-3 text-center">
                <p className="text-danger">{error ? errorMessage(error) : ''}</p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>

                <ImageInput register={register} currentPhoto={currentPhoto} imageInput={imageInput}
                            setCurrentPhoto={setCurrentPhoto} defaultImage={doctor.image}/>

                <div className="form-group">
                  <label htmlFor="first-name-input">Име</label>
                  <input
                    id="first-name-input"
                    type="text"
                    {...register('firstName', { required: 'Моля въведете първото си име' })}
                    className="form-control"
                    placeholder="Въведете своето име"
                    defaultValue={doctor.firstName || ''}
                  />
                  {errors.firstName && <p className="text-danger">Това поле е задължително!</p>}
                </div>

                <div className="form-group">
                  <label htmlFor="last-name-input">Фамилия</label>
                  <input
                    id="last-name-input"
                    type="text"
                    {...register('lastName', { required: 'Моля въведете своеята фамилия' })}
                    className="form-control"
                    placeholder="Въведете своята фамилия"
                    defaultValue={doctor.lastName || ''}
                  />
                  {errors.lastName && <p className="text-danger">Това поле е задължително!</p>}
                </div>

                <div className="form-group">
                  <label htmlFor="email-input">Имейл адрес</label>
                  <input
                    id="email-input"
                    type="email"
                    {...register('email', { required: 'Моля въведете телефонен имейл' })}
                    className="form-control"
                    placeholder="Въведете своя имейл адрес"
                    defaultValue={doctor.email || ''}
                  />
                  {errors.email && <p className="text-danger">Това поле е задължително!</p>}
                </div>

                <div className="form-group">
                  <label htmlFor="phone-number">Телефонен номер</label>
                  <input
                    id="phone-number"
                    type="phoneNumber"
                    {...register('phoneNumber')}
                    className="form-control"
                    placeholder="Въведете своя телефонен номер"
                    defaultValue={doctor.phoneNumber || ''}
                  />
                  {errors.phoneNumber && <p className="text-danger">Това поле е задължително!</p>}
                </div>

                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                >
                  {isLoading || formIsLoading ? (<LoadingSpinner dark={false}/>) : 'Запазване'}
                </button>

                <Link
                  to="/change-password"
                  className="btn btn-warning btn-block"
                >
                  Смяна на паролата
                </Link>
              </form>

              <a href="#modal2" className="text-white text-decoration-none" data-toggle="modal">
                <div className="btn btn-danger mt-2 btn-block">
                  <FontAwesomeIcon icon={faTrash} className="mr-2"/>
                  Изтрий профил
                </div>
              </a>
              <ModalDelete
                handleDelete={handleDeleteDoctorProfile}
                profile={`${doctor.firstName} ${doctor.lastName}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
