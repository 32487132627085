import React, { FC } from 'react';
import { ProceduresTableRender } from './ProceduresTableRender';
import { PageType } from '../shared/TableMenu';

export const ProceduresTable: FC = (): JSX.Element => {

  return (
    <div className='patients-content'>
      <ProceduresTableRender type={PageType.PROCEDURES} />
    </div>
  );
};
