import React, { FC } from 'react';

export const LoadingScreen: FC = (): JSX.Element => (
  <div className="container" style={{ height: '100vh' }}>
    <div className="row justify-content-center">
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div className="spinner-border text-primary" role="status"/>
      </div>
    </div>
  </div>
);
