import { DocumentSnapshot, QuerySnapshot , DocumentData} from '@firebase/firestore-types';
import { Doctor, DoctorData } from '../../types/doctors';
import { removeNullValues } from './shared';

export const transformDoctor = (document: DocumentSnapshot): Doctor | null => {
  const data = document.data();

  if (!data) {
    return null;
  }

  return {
    id: document.id,
    ...data as DoctorData,
    createdAt: data.createdAt.toDate(),
    updatedAt: data.updatedAt.toDate()
  };
};

export const transformDoctors = (querySnapshot: QuerySnapshot<DocumentData>): Doctor[] => {
  if (querySnapshot.empty) {
    return [];
  }

  return querySnapshot
    .docs
    .map(transformDoctor)
    .filter(removeNullValues) as Doctor[];
};
