import { PatientHealthData } from '../patients';
import { ProcedureType } from '../procedures';

export enum FieldTypes {
  TEXT = 'text',
  TEXT_REQUIRED = 'text-required',
  CHECKBOX = 'checkbox',
  CHECKBOX_REQUIRED = 'checkbox-required',
  CHECKBOX_AND_TEXT = 'checkbox-and-text',
  CHECKBOX_AND_TEXT_REQUIRED = 'checkbox-and-text-required',
  TEXTAREA = 'textarea',
  TEXTAREA_REQUIRED = 'textarea-required',
  DATEPICKER = 'datepicker',
  SIGNATURE_DOCTOR = 'signature-doctor',
  SIGNATURE_WITNESS = 'signature-witness',
  SIGNATURE = 'signature',
  PARAGRAPH = 'paragraph',
  SINGLE_SELECT = 'single-select', //TODO
  MULTI_SELECT = 'multi-select' //TODO
}

export type FormFieldName = keyof PatientHealthData & ProcedureType;

export interface InputFormField {
  type: FieldTypes;
  name: string;
  label: string;
  placeholder?: string;
  validation?: unknown;
}

export interface CheckboxWithTextField extends InputFormField {
  inputName: string;
  inputLabel: string;
  inputNameTwo?: string;
  inputLabelTwo?: string;
}

export interface SingleSelectField extends InputFormField {
  options: {
    value: string;
    label: string;
  }[]
}

export interface Paragraph {
  text: string;
  type: FieldTypes.PARAGRAPH;
}

export type FormField = InputFormField | CheckboxWithTextField | SingleSelectField | Paragraph;
