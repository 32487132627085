import firebase from 'firebase/app';
import { Doctor } from '../types/doctors';

export interface AuthContextValues {
  isLoading: boolean;
  error: firebase.auth.Error | null;
  user: firebase.User | null;
  userData: Doctor | null;
  emailAction: AuthEmailAction | null;
  queryParams: AuthQueryParameters | null;
  resetPasswordAction: AuthResetPasswordActionData | null;
  signInAction: AuthSignInActionData | null;
  providers: string[];
  isAdmin: boolean; // TODO - Check
}

export interface AuthContextFunctions {
  signInWithEmailAndPassword: (data: AuthSignInData) => Promise<void>;
  signUpWithEmailAndPassword: (data: AuthSignUpData) => Promise<void>;
  signOut: () => Promise<void>;
  sendResetPasswordEmail: (data: AuthSendResetPasswordEmailData) => Promise<void>;
  verifySignInLink: (data: AuthVerifySignInLinkData) => Promise<void>;
  signInWithEmailLink: (data: AuthSignInWithEmailLinkData) => Promise<void>;
  verifyResetPasswordCode: (data: AuthResetPasswordVerificationData) => Promise<void>;
  resetPassword: (data: AuthResetPasswordData) => Promise<void>;
  sendInvitationEmail: (data: AuthSendInvitationEmailData) => Promise<void>;
}

export type AuthContextValueAndFunctions = AuthContextValues & AuthContextFunctions;

export interface AuthSignInData {
  email: string;
  password: string;
}

export interface AuthSignUpData {
  email: string;
  password: string;
}

export interface AuthSendResetPasswordEmailData {
  email: string;
}

export interface AuthResetPasswordVerificationData {
  oobCode: string;
}

export interface AuthResetPasswordData {
  oobCode: string;
  email: string;
  password: string;
}

export interface AuthResetPasswordActionData {
  email: string;
  emailSent: boolean;
  success: boolean;
}

export interface AuthSendInvitationEmailData {
  url: string;
  email: string;
}

export interface AuthInvitationData {
  email: string;
  password: string;
}

export interface AuthVerifySignInLinkData {
  url: string;
}

export interface AuthSignInActionData {
  isValidUrl: boolean;
}

export interface AuthSignInWithEmailLinkData {
  email: string;
  url: string;
}

export interface AuthQueryParameters {
  mode: string;
  oobCode: string;
  apiKey: string;
  lang: string;
  continueUrl: string | null;
}

export enum AuthEmailAction {
  RESET_PASSWORD = 'resetPassword',
  RECOVER_EMAIL = 'recoverEmail',
  VERIFY_EMAIL = 'verifyEmail',
  SIGN_IN = 'signIn'
}
