import { firestore, createTimestamps } from '../index';
import { CreatePatientData, OptionalPatientData, Patient, PatientInput } from '../../types/patients';
import { DentDocCollections } from '../constants/collections';
import { transformPatient, transformPatients } from '../transformers/patients';

export const createPatient = async (doctorId: string, patientId: string, patientData: CreatePatientData) => {
  const patient: PatientInput = {
    ...patientData,
    ...createTimestamps()
  };

  const documentRef = firestore
    .collection(`${DentDocCollections.DOCTORS}/${doctorId}/${DentDocCollections.PATIENTS}`)
    .doc(patientId);

  await documentRef
    .set(patient);

  return documentRef.get();
};

export const getPatient = async (doctorId: string, patientId: string): Promise<Patient | null> => {
  const querySnapshot = await firestore
    .collection(`${DentDocCollections.DOCTORS}/${doctorId}/${DentDocCollections.PATIENTS}`)
    .doc(patientId)
    .get();

  if (!querySnapshot) {
    return null;
  }

  return transformPatient(querySnapshot);
};

export const getPatients = async (doctorId: string): Promise<Patient[]> => {
  const querySnapshot = await firestore
    .collection(`${DentDocCollections.DOCTORS}/${doctorId}/${DentDocCollections.PATIENTS}`)
    .get();

  if (!querySnapshot) {
    return [];
  }

  return transformPatients(querySnapshot);
};

export const updatePatient = async (doctorId: string, patientId: string, patientData: OptionalPatientData): Promise<void> => {
  await firestore
    .collection(`${DentDocCollections.DOCTORS}/${doctorId}/${DentDocCollections.PATIENTS}`)
    .doc(patientId)
    .update({
      ...patientData,
      updatedAt: createTimestamps().updatedAt
    });
};

export const deletePatient = async (doctorId: string, patientId: string): Promise<void> => {
  await firestore
    .collection(`${DentDocCollections.DOCTORS}/${doctorId}/${DentDocCollections.PATIENTS}`)
    .doc(patientId)
    .delete();
};
