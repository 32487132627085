import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { AppRoutes } from '../../constants/routes';

export interface TableMenuProps {
  pageSize: any;
  setPageSize: any;
  pageType: PageType;
}

export enum PageType {
  DOCTORS = 0,
  PATIENTS = 1,
  PROCEDURES = 3,
}

export const TableMenu: FC<TableMenuProps> = ({ pageSize, setPageSize, pageType }: TableMenuProps): JSX.Element => {
  const { patientId } = useParams<{ [patientId: string]: string }>();

  return (
    <div className="d-flex justify-content-between mb-5">
      {(pageType === PageType.DOCTORS || pageType === PageType.PATIENTS) &&
      <Link
        className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
        to={`${pageType === PageType.DOCTORS
          ? AppRoutes.ADD_DOCTOR
          : pageType === PageType.PATIENTS ? AppRoutes.ADD_PATIENT
            : `/patients/${patientId}/start-procedure`

        }`}
      >
        <FontAwesomeIcon
          icon={faPlus}
          className="wd-10 mg-r-5 plus-icon"
        />
        {pageType === PageType.DOCTORS
          ? 'Добави лекар' :
          pageType === PageType.PATIENTS
            ? 'Добави пациент'
            : 'Процедури'}
      </Link>
      }


      <select
        className="btn btn-sm pd-x-15 btn-info btn-uppercase mg-l-5"
        value={pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map(pageSize => (
          <option className="dropdown-item" key={pageSize} value={pageSize}>
            Покажи {pageSize}
          </option>
        ))}
      </select>
    </div>

  );
};
