import React, { FC } from 'react';

interface ModalDeleteProps {
  handleDelete: () => void;
  profile: string;
}

export const ModalDelete: FC<ModalDeleteProps> = ({handleDelete, profile}): JSX.Element => {

  return (
    <div className="modal fade" id="modal2" tabIndex={-1} role="dialog"
         aria-labelledby="exampleModalLabel2"
         aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content tx-14">
          <div className="modal-header">
            <h6 className="modal-title" id="exampleModalLabel2">Изтриване!</h6>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h2>Изтриване на {profile}</h2>
            <p className="mg-b-0">Сигурни ли сте, че искате да изтриете този профил?</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light tx-13" data-dismiss="modal">Назад</button>
            <button
              onClick={handleDelete}
              type="button"
              data-dismiss="modal"
              className="btn btn-danger tx-13">Да
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
