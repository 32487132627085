import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { SignIn } from './components/signInForms/SignIn';
import Doctors from './components/Doctors';
import { NavBar } from './components/layout/NavBar';
import { Footer } from './components/layout/Footer';
import { ForgotPassword } from './components/profile/ForgotPassword';
import { AddDoctor } from './components/doctors/AddDoctor';
import { EditDoctor } from './components/doctors/EditDoctor';
import { ChangePassword } from './components/doctors/ChangePassword';
import { Wizard } from './components/wizard/Wizard';

import { useAuth } from './contexts/AuthContext';
import { AuthEmailAction } from './contexts/auth-types';
import { UpdatePassword } from './components/signInForms/UpdatePassword';
import { LoadingScreen } from './components/LoadingScreen';
import { PasswordReset } from './components/profile/PasswordReset';
import { PatientProfile } from './components/patients/PatientProfile';
import { AddPatient } from './components/patients/AddPatient';
import { PatientsTable } from './components/patients/PatientsTable';
import { CreateProcedure } from './components/procedures/CreateProcedure';
import { AppRoutes } from './constants/routes';
import { DoctorProfile } from './components/doctors/DoctorProfile';
import { EditPatient } from './components/patients/EditPatient';
import { InvitationPage } from './components/doctors/InvitationPage';
import { FinishWizard } from './components/wizard/FinishWizard';

enum FormType {
  NO_FORM,
  SIGN_IN,
  SIGN_UP,

  // Reset password flow
  SEND_RESET_PASSWORD_EMAIL,
  RESET_PASSWORD,

  // Invite user flow
  SEND_INVITATION_EMAIL,
  INVITATION
}

const AdminProtectedRoute = (props: any) => {
  const { user, isAdmin } = useAuth();

  return (
    <Route {...props}>
      {user && isAdmin
        ? props.children
        : <Redirect to={'/'}/>}
    </Route>
  );
};

const ProtectedRoute = (props: any) => {
  const { user } = useAuth();

  return (
    <Route {...props}>
      {user
        ? props.children
        : <Redirect to={'/'}/>}
    </Route>
  );
};


const App = () => {
  const [formType, setFormType] = useState<FormType>(FormType.NO_FORM);
  const { sendInvitationEmail, emailAction, signInWithEmailLink, isLoading, user, verifySignInLink } = useAuth();
  const urlSplit = window.location.href.split('/');
  const email = urlSplit[urlSplit.length - 1].split('&')[0];
  const [isEmailActionLoading, setIsEmailActionLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsEmailActionLoading(true);
    if (emailAction !== AuthEmailAction.SIGN_IN) {
      setIsEmailActionLoading(false);
      return;
    }

    if (user) {
      setIsEmailActionLoading(false);
      return;
    }

    (async () => {
      await verifySignInLink({ url: window.location.href });


      await signInWithEmailLink({
        email,
        url: window.location.href
      });
      setIsEmailActionLoading(false);
    })();

  }, [email, emailAction, signInWithEmailLink, user, verifySignInLink]);

  useEffect(() => {
    setIsEmailActionLoading(true);
    switch (emailAction) {
      case AuthEmailAction.RESET_PASSWORD:
        setFormType(FormType.RESET_PASSWORD);
        break;

      case AuthEmailAction.SIGN_IN:
        setFormType(FormType.INVITATION);
        break;
    }
    setIsEmailActionLoading(false);
  }, [emailAction, setFormType]);


  const renderForm = (formType: FormType) => {
    if (formType === FormType.INVITATION) {
      return <InvitationPage email={email} emailActionLoading={isEmailActionLoading}/>;
    }

    if (formType === FormType.RESET_PASSWORD) {
      return <PasswordReset/>;
    }

    return <SignIn/>;
  };

  if (isLoading || isEmailActionLoading) {
    return (<LoadingScreen/>);
  }


  return (
    <Router>
      <Switch>
        <Route exact={true} path={AppRoutes.AUTH_FORM}>
          {renderForm(formType)}
        </Route>
        <Route exact={true} path={AppRoutes.FORGOT_PASSWORD}>
          <ForgotPassword/>
        </Route>
        <ProtectedRoute exact={true} path={AppRoutes.WIZARD}>
          <Wizard/>
        </ProtectedRoute>
        <ProtectedRoute exact={true} path={AppRoutes.WIZARD_FINISH}>
          <FinishWizard/>
        </ProtectedRoute>
        <ProtectedRoute exact={true} path={AppRoutes.UPDATE_PASSWORD}>
          <UpdatePassword/>
        </ProtectedRoute>

        <Route>
          <NavBar/>
          <AdminProtectedRoute exact={true} path={AppRoutes.DOCTORS}>
            <Doctors/>
          </AdminProtectedRoute>
          <AdminProtectedRoute exact={true} path={AppRoutes.ADD_DOCTOR}>
            <AddDoctor sendInvitationEmail={sendInvitationEmail}/>
          </AdminProtectedRoute>
          <ProtectedRoute exact={true} path={AppRoutes.DOCTOR_PROFILE}>
            <DoctorProfile/>
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={AppRoutes.EDIT_DOCTOR}>
            <EditDoctor/>
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={AppRoutes.CHANGE_PASSWORD}>
            <ChangePassword/>
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={AppRoutes.PATIENTS}>
            <PatientsTable/>
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={AppRoutes.ADD_PATIENT}>
            <AddPatient/>
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={AppRoutes.PATIENT_PROFILE}>
            <PatientProfile/>
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={AppRoutes.EDIT_PATIENT}>
            <EditPatient/>
          </ProtectedRoute>
          <ProtectedRoute exact={true} path={AppRoutes.START_PROCEDURE}>
            <CreateProcedure/>
          </ProtectedRoute>
          <Footer/>
        </Route>
        <Redirect to="/"/>
      </Switch>
    </Router>
  );
};

export default App;
