import React, { FC } from 'react';
import { DoctorsTable } from './doctors/DoctorsTable';

const Doctors: FC = (): JSX.Element => (
  <div>
    <DoctorsTable/>
  </div>
);

export default Doctors;
