import React, { FC, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { errorMessage } from '../../errors/errors';
import { useAuth } from '../../contexts/AuthContext';
import { sendInvitationEmail } from '../../firebase/functions/auth';
import { LoadingSpinner } from '../LoadingSpinner';
import { AuthSendInvitationEmailData } from '../../contexts/auth-types';

interface InviteDoctorValues {
  email: string;
}

interface AddDoctorProps {
  sendInvitationEmail: (value: AuthSendInvitationEmailData) => Promise<void>;
}

export const AddDoctor: FC<AddDoctorProps> = (): JSX.Element => {
  const { error, isLoading } = useAuth();
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm<InviteDoctorValues>();
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const [inviteSent, setInviteSent] = useState<boolean>(false);

  const onSubmit = async (values: InviteDoctorValues) => {
    setFormIsLoading(true);
    try {
      await sendInvitationEmail(values.email);
      setInviteSent(true);
      // await history.push('/doctors');
    } catch (e) {
      console.error(e);
    }
    setFormIsLoading(false);
  };

  return (
    <div className="doctors-content">
      <div className="content content-fixed">
        <div className="container mt-8 pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <NavLink to="/">DentDoc</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Добавяне на Лекар</li>
                </ol>
              </nav>
              <h4 className="mg-b-0">Добавяне на Лекар</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div className="sign-wrapper">
            {inviteSent
              ?
              <div className="wd-100p">
                <h3 className="tx-color-01 mg-b-40 text-center">Поканта е изпратена.</h3>
                <button
                  onClick={() => history.push('/doctors')}
                  className="btn btn-primary btn-lg btn-block">OK
                </button>
                <div className="mt-3">
                  <p className="text-danger">{error ? errorMessage(error) : ''}</p>
                </div>
              </div>
              :
              <div className="wd-100p">
                <h3 className="tx-color-01 mg-b-5">Добави Лекар</h3>
                <p className="tx-color-03 tx-16 mg-b-40">
                  На този имейл адрес ще изпратим покана за системата.
                </p>

                <div className="mt-3">
                  <p className="text-danger">{error ? errorMessage(error) : ''}</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label htmlFor="email-input">Имейл адрес</label>
                    <input
                      id="email-input"
                      type="email"
                      {...register('email', { required: 'Моля въведете имейл' })}
                      className="form-control"
                      placeholder="Въведете имейл адрес"
                    />
                    {errors.email && <p className="text-danger">Това поле е задължително!</p>}
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                  >{isLoading || formIsLoading ? (
                      <span>Изпращане на покана ...</span>
                  ) : <span>Добавяне</span>}
                  </button>
                  {(isLoading || formIsLoading) &&
                  <div className='d-flex justify-content-center my-4'>
                    <LoadingSpinner dark={false}/>
                  </div>
                  }
                </form>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
};
