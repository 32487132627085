import { firestore, createTimestamps } from '../index';
import { OptionalProcedureData, Procedure, ProcedureData, ProcedureInput } from '../../types/procedures';
import { DentDocCollections } from '../constants/collections';
import { transformProcedure, transformProcedures } from '../transformers/procedures';
import { DocumentReference } from '@firebase/firestore-types';

export const createProcedure = async (
  doctorId: string,
  patientId: string,
  procedureData: ProcedureData
): Promise<DocumentReference> => {
  const procedure: ProcedureInput = {
    ...procedureData,
    ...createTimestamps()
  };

  return firestore
    .collection(`${DentDocCollections.DOCTORS}/${doctorId}/${DentDocCollections.PATIENTS}/${patientId}/${DentDocCollections.PROCEDURES}`)
    .add(procedure);
};

export const getProcedure = async (
  doctorId: string,
  patientId: string,
  procedureId: string
): Promise<Procedure | null> => {
  const querySnapshot = await firestore
    .collection(`${DentDocCollections.DOCTORS}/${doctorId}/${DentDocCollections.PATIENTS}/${patientId}/${DentDocCollections.PROCEDURES}`)
    .doc(procedureId)
    .get();

  if (!querySnapshot) {
    return null;
  }

  return transformProcedure(querySnapshot);
};

export const getProcedures = async (
  doctorId: string,
  patientId: string,
): Promise<Procedure[]> => {
  const querySnapshot = await firestore
    .collection(`${DentDocCollections.DOCTORS}/${doctorId}/${DentDocCollections.PATIENTS}/${patientId}/${DentDocCollections.PROCEDURES}`)
    .get();

  if (!querySnapshot) {
    return [];
  }

  return transformProcedures(querySnapshot);
};

export const updateProcedure = async (
  doctorId: string,
  patientId: string,
  procedureId: string,
  procedureData: OptionalProcedureData
): Promise<void> => {
  await firestore
    .collection(`${DentDocCollections.DOCTORS}/${doctorId}/${DentDocCollections.PATIENTS}/${patientId}/${DentDocCollections.PROCEDURES}`)
    .doc(procedureId)
    .update({
      ...procedureData,
      updatedAt: createTimestamps().updatedAt
    });
};

export const deleteProcedure = async (
  doctorId: string,
  patientId: string,
  procedureId: string,
): Promise<void> => {
  await firestore
    .collection(`${DentDocCollections.DOCTORS}/${doctorId}/${DentDocCollections.PATIENTS}/${patientId}/${DentDocCollections.PROCEDURES}`)
    .doc(procedureId)
    .delete();
};
