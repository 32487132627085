import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { AuthContextProvider } from './contexts/AuthContext';
import { WizardContextProvider } from './contexts/WizardContext';
import './scss/dashforge.scss';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://17dfa65470054b0dae75ce1d22358a77@o479719.ingest.sentry.io/5712899',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <BrowserRouter>
        <AuthContextProvider>
          <WizardContextProvider>
            <App/>
          </WizardContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
