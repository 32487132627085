export const imagePreview = (e: any, state: any, setState: any) => {
  if (e.target.files.length === 0) {
    return;
  }

  const file = e.target.files[0]
  const fileReader = new FileReader();

  fileReader.onload = (e) => {
    setState((state: any) => ({
      ...state,
      image: e.target !== null ? e.target.result as string : state.image
    }))
  }

  fileReader.readAsDataURL(file)
}
