import useSWR, { mutate as globalMutate } from 'swr';
import {
  createProcedure,
  deleteProcedure,
  getProcedure,
  getProcedures,
  updateProcedure
} from '../firebase/firestore/procedures';
import { DOCTORS, PATIENTS, PROCEDURES } from '../constants/firebase/collections';
import { ProcedureData, ProcedureInput } from '../types/procedures';

const db = {
  createProcedure,
  deleteProcedure,
  getProcedure,
  getProcedures,
  updateProcedure
}

export const useProcedures = (doctorId: string, pin: string) => {
  const {
    data,
    error
  } = useSWR(`${DOCTORS}/${doctorId}/${PATIENTS}/${pin}/${PROCEDURES}`, () => db.getProcedures(doctorId, pin));

  return {
    userData: data,
    isLoading: !error && !data,
    error,
  };
};

export const useProcedure = (doctorId: string, pin: string, procedureId: string) => {
  const {
    data,
    error,
    mutate
  } = useSWR(`${DOCTORS}/${doctorId}/${PATIENTS}/${pin}/${PROCEDURES}/${procedureId}`, () => db.getProcedure(doctorId, pin, procedureId));

  return {
    userData: data,
    isLoading: !error && !data,
    error,
    procedureUpdate: async (newData: ProcedureInput) => {
      console.log(`update: ${DOCTORS}/${doctorId}/${PATIENTS}/${pin}/${PROCEDURES}/${procedureId}`);
      await db.updateProcedure(doctorId, pin, procedureId, newData);
      await mutate();
    },
    procedureDelete: async (doctorId: string, patientId: string, procedureId: string) => {
      await db.deleteProcedure(doctorId, patientId, procedureId);
      await mutate();
      await globalMutate(`${DOCTORS}/${doctorId}/${PATIENTS}/${patientId}/${PROCEDURES}`);
    }
  };
};

export const procedureCreate = async (doctorId: string, patientId: string, data: ProcedureData) => {
  await db.createProcedure(doctorId, patientId, data);
  await globalMutate(`${DOCTORS}/${doctorId}/${PATIENTS}/${patientId}/${PROCEDURES}`);
};
