import React, { FC, useEffect } from 'react';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { usePatient } from '../../data-hooks/patients';
import { ProceduresTable } from '../procedures/ProceduresTable';
import { useWizard } from '../../contexts/WizardContext';
import { useAuth } from '../../contexts/AuthContext';

export const PatientProfile: FC = (): JSX.Element => {
  const { resetState, updatePatientData } = useWizard();
  const { user } = useAuth();
  const { patientId } = useParams<{ [patientId: string]: string }>();
  const { userData, isLoading } = usePatient(patientId, user?.uid as string);
  const history = useHistory();

  useEffect(() => {
    resetState();
  });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const startProcedure = () => {
    updatePatientData({
      fullName: userData?.fullName,
      personalId: userData?.personalId,
    });
    history.push(`/patients/${patientId}/start-procedure`);
  };

  return (
    <div className="doctors-content">
      <div className="content content-fixed">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <NavLink to="/">InformDental</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Пациенти</li>
                </ol>
              </nav>
              <h4 className="mg-b-0">Пациентски профил</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="">
            <div className="pd-t-20 wd-100p d-flex justify-content-between">
              <div className="">
                <div className="form-group">
                  <div className="form-group">
                    <label className="animated bounce" htmlFor="first-name-input">Име</label>
                    <p className="text-primary pl-2">{userData?.fullName}</p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email-input">EГН</label>
                    <p className="text-primary pl-2">{userData?.personalId}</p>
                  </div>
                  <Link
                    to={`/patients/${patientId}/edit`}
                    className="btn btn-sm btn-white">
                    <FontAwesomeIcon icon={faPen} className="mr-2"/>
                    Редактирай
                  </Link>
                </div>
              </div>

              <div className="d-flex d-lg-block d-sm-block text-center p-4 w-50">
                <button
                  className="btn btn-primary mb-2"
                  onClick={() => startProcedure()}
                >
                  Започни процедура
                </button>
              </div>
            </div>
          </div>
        </div>
        <ProceduresTable/>
      </div>
    </div>
  );
};
