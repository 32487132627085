import React, { FC, useMemo } from 'react';
import { useDoctors } from '../../data-hooks/doctors';
import { TableLayout } from '../shared/TableLayout';
import { Doctor } from '../../types/doctors';
import { LoadingScreen } from '../LoadingScreen';
import { PageType } from '../shared/TableMenu';
import { useAuth } from '../../contexts/AuthContext';

enum TableHeaders {
  FIRSTNAME = 'Име',
  LASTNAME = 'Фамилия',
  EMAIL = 'Имейл',
}

enum TableAccessors {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  EMAIL = 'email',
}

export const DoctorsTableRender: FC = () => {
  const { doctors, isLoading, error } = useDoctors();
  const { user } = useAuth();

  const doctorData: Doctor[] = useMemo(() =>
      !doctors ? [] : doctors.filter(d => d.id !== user?.uid),
    [doctors, user?.uid]);

  const doctorColumns = useMemo(() => [
    {
      Header: TableHeaders.FIRSTNAME,
      accessor: TableAccessors.FIRSTNAME,
    },
    {
      Header: TableHeaders.LASTNAME,
      accessor: TableAccessors.LASTNAME,
    },
    {
      Header: TableHeaders.EMAIL,
      accessor: TableAccessors.EMAIL,
    },
  ], []);

  if (isLoading) {
    return <LoadingScreen/>;
  }

  return (
    <>
      <TableLayout
        columns={doctorColumns}
        data={doctorData}
        type={PageType.DOCTORS}
      />
      {error && <p className="alert alert-danger text-center" role="alert">{error}</p>}
    </>
  );
};
