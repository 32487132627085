import { storage } from './index';

export const uploadImage = async (file: any) => {
  const metadata = {
    contentType: 'image/jpeg'
  };

  if (file) {
    const storageRef = await storage.ref();

    const imageRef = storageRef
      .child('doctor-images/' + file.name + Date.now());

    try {
      await imageRef.put(file, metadata);
      console.log('uploaded');

    } catch (e) {
      console.log({ e });
    }
    return imageRef.getDownloadURL();
  }
};
