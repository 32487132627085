import { EntityBase, InputBase } from '../../../functions/src/types/firebase';
import { PatientData } from './patients';

interface SurgeryConsent {
  // Съгласие за оперативна намеса
  healthRisks: boolean;
  alternativeMethods: boolean;
  surgeryAnesthesia: boolean;
  surgerySideEffects: boolean;
  surgeryAllergies: boolean;
  surgeryAgreement: boolean;
  surgeryRestrictions: boolean;
  surgeryAnswers: boolean;
  surgerySmoking: boolean;
  surgeryDoctor: boolean;
  surgeryRisks: boolean;
  surgeryConsent: boolean;
}

interface InformedConsent {
  // Информирано съгласие
  selectTypeOfClient: string;
  selectConsent: string;
}

interface SignatureData {
  // Подпис
  date: Date;
  patientSignature: string;
  doctorSignature: string;
  witnessName?: string;
}

interface InstructionsConsent {
  followInstructions: boolean;
}

export interface ExtractionProcedure extends
  SurgeryConsent,
  InformedConsent,
  InstructionsConsent,
  SignatureData {
  // Лични данни
  // Лична информация
  teeth: string;
  teethNumber: string;
  teethDeclaration: boolean;
  teethAnesthesia: boolean;
  teethAnesthesiaConsent: boolean;
  teethAllergies: boolean;
  teethInfoDeclaration: boolean;
  collagen: boolean;
  membrane: boolean;
  platelet: boolean;
  alveoli: boolean;
  // Съгласие за оперативна намеса
  // Информирано съгласие
  // Инструкции
  // Подпис
}

interface SurgeryProcedure extends
  SurgeryConsent,
  InformedConsent,
  InstructionsConsent,
  SignatureData {
  // Лични данни
  // Лична информация
  surgeryProcedureConsent: boolean;
  surgeryCondition: boolean;
  surgeryProcedures: boolean;
  surgeryRisks: boolean;
  surgeryAlternatives: boolean;
  surgeryConsequences: boolean;
  surgeryOtherConsequences: boolean;
  surgeryComplications: boolean;
  surgeryRecovery: boolean;
  surgeryRecoveryProcedures: boolean;
  surgeryCancelConsent: boolean;
  // Съгласие за оперативна намеса
  // Информирано съгласие
  // Инструкции
  // Подпис
}

interface EndodontalProcedure extends InformedConsent, SignatureData {
  // Лични данни
  // Лична информация
  endodontConsent: boolean;
  procedure: boolean;
  risks: boolean;
  informedConsent: boolean;
  // Информирано съгласие
  // Подпис
}

interface ImplantProcedure extends SurgeryConsent,
  InformedConsent,
  InstructionsConsent,
  SignatureData {
  // Лични данни
  // Лична информация
  kindOfImplants: string;
  implantsPositions: string;
  lifting: string;
  boneBuilding: string;
  membraneCount: string;
  prfPrp: string;
  boneMaterials: string;
  firstTimeEstimation: string;
  firstTimeEstimationText: string;
  secondTimePeriod: string;
  secondTimeEstimation: string;
  secondTimeEstimationText: string;
  receivedInformationAboutTreatment: boolean;
  continueWithTreatment: boolean
  // Съгласие за оперативна намеса
  // Информирано съгласие
  // Инструкции
  // Подпис
}

interface PeriodontalProcedure extends InformedConsent, SignatureData {
  // Лични данни
  // Лична информация
  // Информирано съгласие
  // Подпис
}

interface OralHygieneProcedure extends SignatureData {
  // Лични данни
  // Лична информация
  consent: string;
  paymentForTreatment: boolean;
  alternatives: string;
  risks: boolean;
  anesthesia: boolean;
  unpredictedConditions: boolean;
  informedConsent: boolean;
  // Подпис
}

interface ProstheticsProcedure extends InformedConsent, SignatureData {
  // Лични данни
  // Лична информация
  altMethods: boolean;
  infoConsent: boolean;
  treatmentPlan: string;
  price: string;
  acceptProsthesis: boolean;
  teethDeath: string;
  visitation: string;
  // Информирано съгласие
  // Подпис
}

interface PhotoConsentProcedure extends SignatureData {
  photoConsent: string;
  photoRights: boolean;
  // Подпис
}

interface PediatricProcedure extends SignatureData {
  // Лични данни
  // Лична информация
  proceduresRisks?: boolean;
  anesthesia?: boolean;
  alternatives?: boolean;
  additionalManipulations?: boolean;
  complications?: boolean;
  specialSituations?: boolean;
  // Подпис
}

export type ProcedureType =
  | ExtractionProcedure
  | SurgeryProcedure
  | EndodontalProcedure
  | ImplantProcedure
  | PeriodontalProcedure
  | OralHygieneProcedure
  | ProstheticsProcedure
  | PhotoConsentProcedure
  | PediatricProcedure

export enum ProcedureTypeEnum {
  EXTRACTION = 'extraction',
  SURGERY = 'surgery',
  ENDODONTAL = 'endodont',
  IMPLANT = 'implant',
  PERIODONTAL = 'periodont',
  ORAL_HYGIENE = 'oral-hygiene',
  PROSTHETICS = 'protetika',
  PHOTO_CONSENT = 'photo-consent',
  PEDIATRICS = 'pediatrics',
}

export interface ProcedureData {
  doctorID: string;
  doctorName: string;
  doctorEmail: string;
  // TODO Check
  patientID: string;
  type: ProcedureTypeEnum;
  data: ProcedureType & PatientData;
}

export type OptionalProcedureData = Partial<ProcedureData>

// noinspection SpellCheckingInspection
export enum ProcedureName {
  EXTRACTION = 'Изваждане на зъби',
  SURGERY = 'Оперативна процедура',
  ENDODONTIA = 'Съгласие за ендодонтско лечение',
  IMPLANTS = 'Импланти',
  PERIODONTAL = 'Пародонтология',
  ORAL_HYGIENE = 'Съгласие за провеждане на клинична орална хигиена',
  PROTETIKA = 'Протетика',
  PHOTO = 'Съгласие за снимки и записи',
  PEDIATRIC = 'Детска дентална медицина'
}

export interface ProcedureInput extends ProcedureData, InputBase {}

export interface Procedure extends ProcedureData, EntityBase {}
