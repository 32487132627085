import React, { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../contexts/AuthContext';
import { errorMessage } from '../../errors/errors';

interface ResetPasswordFormData {
  email: string;
  password: string;
}

export const PasswordReset: FC = (): JSX.Element => {
  const {
    isLoading,
    queryParams,
    verifyResetPasswordCode,
    resetPasswordAction,
    resetPassword,
    error
  } = useAuth();

  const { handleSubmit, formState: { errors }, register, setValue } = useForm<ResetPasswordFormData>();

  useEffect(() => {
    if (resetPasswordAction && resetPasswordAction.email) {
      setValue('email', resetPasswordAction.email);
    }
  }, [resetPasswordAction, setValue]);

  const onFormSubmit = useCallback(async (values: ResetPasswordFormData) => {
    if (!queryParams) {
      return;
    }

    if (!queryParams.oobCode) {
      return;
    }

    await resetPassword({
      oobCode: queryParams.oobCode,
      ...values
    });

    window.location.replace('/');

  }, [queryParams, resetPassword]);

  useEffect(() => {
    (async () => {
      if (!queryParams) {
        return;
      }

      if (!queryParams.oobCode) {
        return;
      }

      // if (resetPasswordAction?.emailVerified) { // TODO check flow
      //   return;
      // }

      await verifyResetPasswordCode({ oobCode: queryParams.oobCode });
    })();
  }, [queryParams, verifyResetPasswordCode, resetPasswordAction]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className="content content-fixed content-auth-alt">
        <div className="container d-flex justify-content-center ht-100p">
          <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
            <h4 className="tx-20 tx-sm-24">Смяна на парола</h4>
            <p className="tx-color-03 mg-b-30 tx-center">
              Моля въведете новата си парола.
            </p>

            <div className="mt-3">
              <p className="text-danger">{error ? errorMessage(error) : ''}</p>
            </div>

            <div className="wd-100p d-flex flex-column flex-sm-row mg-b-40">
              <input
                {...register('email', { required: 'Моля въведете имейл' })}
                id="email"
                type="email"
                name="email"
                disabled={true}
                className="form-control wd-sm-250 flex-fill"
                placeholder="Потребителско име или имейл адрес"
              />
              {errors.email && (
                <div className="mt-2 text-xs text-red-600">
                  {errors.email.message}
                </div>
              )}
            </div>
            <div className="wd-100p d-flex flex-column flex-sm-row mg-b-40">
              <input
                {...register('password', { required: 'Моля въведете парола' })}
                id="password"
                type="password"
                name="password"
                className="form-control wd-sm-250 flex-fill"
                placeholder="Въведете нова парола"
              />
              {isLoading
                ? (<p>Loading...</p>)
                : (<button
                  className="btn btn-primary mg-sm-l-10 mg-t-10 mg-sm-t-0"
                  type="submit"
                >
                  Запазване
                </button>)
              }
              {errors.password && (
                <div className="mt-2 text-xs text-red-600">
                  {errors.password.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
