import { firestore, createTimestamps } from '../index';
import { Doctor, DoctorData, DoctorInput } from '../../types/doctors';
import { DentDocCollections } from '../constants/collections';
import { transformDoctor, transformDoctors } from '../transformers/doctors';
import { OptionalDoctorData } from '../../../../functions/src/types/doctor';

export const createDoctor = async (doctorId: string, doctorData: DoctorData) => {
  const doctor: DoctorInput = {
    ...doctorData,
    ...createTimestamps()
  };

  const documentRef = firestore
    .collection(DentDocCollections.DOCTORS)
    .doc(doctorData.email);

  await documentRef
    .set(doctor);

  return documentRef.get();
};

export const getDoctor = async (doctorId: string): Promise<Doctor | null> => {
  const querySnapshot = await firestore
    .collection(DentDocCollections.DOCTORS)
    .doc(doctorId)
    .get();

  if (!querySnapshot) {
    return null;
  }

  return transformDoctor(querySnapshot);
};

export const getDoctors = async (): Promise<Doctor[]> => {
  const querySnapshot = await firestore
    .collection(DentDocCollections.DOCTORS)
    .get();

  if (querySnapshot.empty) {
    return [];
  }

  return transformDoctors(querySnapshot);
};

export const updateDoctor = async (
  doctorID: string,
  doctorData: OptionalDoctorData
): Promise<void> => {
  await firestore
    .collection(DentDocCollections.DOCTORS)
    .doc(doctorID)
    .update({
      ...doctorData,
      updatedAt: createTimestamps().updatedAt
    });
};

export const deleteDoctor = async (doctorID: string): Promise<void> => {
  await firestore
    .collection(DentDocCollections.DOCTORS)
    .doc(doctorID)
    .delete();
};
