import React, { FC, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useAuth } from '../../contexts/AuthContext';
import { errorMessage } from '../../errors/errors';
import firebase from 'firebase';

interface ChangePasswordFormValues {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const ChangePassword: FC = (): JSX.Element => {
  const { register, handleSubmit, formState: { errors }, watch, clearErrors } = useForm<ChangePasswordFormValues>();
  const password = useRef({});
  password.current = watch('newPassword', '');
  const { error, signInWithEmailAndPassword } = useAuth();
  const user = firebase.auth().currentUser;

  const onSubmit = async (values: ChangePasswordFormValues) => {

    try {
      await signInWithEmailAndPassword({ password: values.oldPassword, email: user?.email as string });
    } catch (e) {
      return console.log(e);
    }

    await user?.updatePassword(values.newPassword);
  };

  return (
    <div className="doctors-content">
      <div className="content content-fixed">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <NavLink to="/">DentDoc</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Смяна на паролата</li>
                </ol>
              </nav>
              <h4 className="mg-b-0">Смяна на паролата</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="media justify-content-center ht-100p">
          <div className="sign-wrapper">
            <div className="pd-t-20 wd-100p">
              <h4 className="tx-color-01 mg-b-5">Сменя на паролата</h4>
              <p className="tx-color-03 tx-16 mg-b-40">От тук можете да смените Вашата парола</p>

              <div className="mt-3 text-center">
                <p className="text-danger">{error ? errorMessage(error) : ''}</p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <div className="form-group">
                    <Link to="/password-forgot" className="tx-13 float-right">Забравена парола?</Link>
                    <label htmlFor="old-password-input">Сегашна парола</label>
                    <input
                      id="old-password-input"
                      {...register('oldPassword', { required: 'Моля въведете старата си парола' })}
                      type="password"
                      onChange={() => clearErrors()}
                      className="form-control"
                      placeholder="Въведете сегашната парола"
                    />
                    {errors.oldPassword && <p className="text-danger">Това поле е задължително!</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="new-password-input">Нова Парола</label>
                    <input
                      id="new-password-input"
                      {...register('newPassword', { required: 'Моля въведете новата си парола' })}
                      type="password"
                      onChange={() => clearErrors()}
                      className="form-control"
                      placeholder="Въведете нова парола"
                    />
                    {errors.newPassword && <p className="text-danger">Това поле е задължително!</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirm-new-password-input">Потвърдете нова парола</label>
                    <input
                      id="confirm-new-password-input"
                      {...register('confirmNewPassword', {
                        validate: value => value === password.current || 'Новата парола трябва да съвпада!',
                        required: 'Това поле е задължително!'
                      })}
                      onChange={() => clearErrors()}
                      type="password"
                      className="form-control"
                      placeholder="Потвърдете нова парола"
                    />
                    {errors.confirmNewPassword && <p className="text-danger">{errors.confirmNewPassword.message}</p>}
                  </div>
                  <button type="submit" className="btn btn-primary btn-block">
                    Запамети
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
