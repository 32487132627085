import React, { FC, useCallback, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { errorMessage } from '../../errors/errors';
import { useAuth } from '../../contexts/AuthContext';
import { usePatient } from '../../data-hooks/patients';
import { LoadingScreen } from '../LoadingScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ModalDelete } from '../shared/ModalDelete';
import { LoadingSpinner } from '../LoadingSpinner';

interface EditPatientValues {
  fullName: string;
  personalId: string;
}

export const EditPatient: FC = (): JSX.Element => {

  const { register, handleSubmit, formState: { errors } } = useForm<EditPatientValues>();
  const { user, error } = useAuth();
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const { patientId } = useParams<{ [patientId: string]: string }>();

  const { userData, patientUpdate, patientDelete } = usePatient(patientId, user?.uid as string);
  console.log(user?.uid as string);
  const onSubmit = useCallback(async (data: EditPatientValues) => {
    setFormIsLoading(true);

    const patientData: EditPatientValues = {
      ...data,
    };

    try {
      await patientUpdate(patientData);
      setFormIsLoading(false);
      history.push(`/patients`);
    } catch (e) {
      console.log(e);
      setFormIsLoading(false);
    }

  }, [history, patientUpdate]);

  const handleDeletePatientProfile = async () => {
    try {
      await patientDelete();
      history.push('/patients');
    } catch (e) {
      console.log(e);
      return { e };
    }
  };

  if (!userData) {
    return <LoadingScreen/>;
  }

  return (
    <div className="doctors-content">
      <div className="content content-fixed">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item">
                    <NavLink to="/">InformDental</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Добавяне на пациент</li>
                </ol>
              </nav>
              <h4 className="mg-b-0">Редактиране на пациент</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div className="sign-wrapper">
            <div className="wd-100p">
              <h3 className="tx-color-01 mg-b-5">Редактиране на пациент</h3>
              <p className="tx-color-03 tx-16 mg-b-40">Попълнете полетата, за да редактиране данните в системата.</p>

              <div className="mt-3">
                <p className="text-danger">{error ? errorMessage(error) : ''}</p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="fullName-input">Три имена:</label>
                  <input
                    id="fullName-input"
                    type="text"
                    {...register('fullName', { required: 'Моля въведете имената на пациента' })}
                    className="form-control"
                    placeholder="Въведете три имена"
                    defaultValue={userData.fullName}
                  />
                  {errors.fullName && <p className="text-danger">Това поле е задължително!</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="personalId-input">ЕГН:</label>
                  <input
                    id="personalId-input"
                    type="number"
                    {...register('personalId', { required: 'Моля въведете ЕГН на пациента' })}
                    className="form-control"
                    readOnly={true}
                    placeholder="Въведете ЕГН"
                    defaultValue={userData.personalId}
                  />
                  {errors.personalId && <p className="text-danger">Това поле е задължително!</p>}
                </div>

                <button
                  type="submit"
                  className="btn btn-primary btn-block">
                  {formIsLoading ? <LoadingSpinner dark={false}/> : 'Запази'}
                </button>

                <a href="#modal2" className="text-white text-decoration-none" data-toggle="modal">
                  <div className="btn btn-danger mt-2 btn-block">
                    <FontAwesomeIcon icon={faTrash} className="mr-2"/>
                    Изтрий профил
                  </div>
                </a>
                <ModalDelete handleDelete={handleDeletePatientProfile} profile={userData.fullName}/>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
