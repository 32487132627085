import React, { FC } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useForm } from 'react-hook-form';
import { errorMessage } from '../../errors/errors';

export const ForgotPassword: FC = (): JSX.Element => {
  const { sendResetPasswordEmail, isLoading, resetPasswordAction, error } = useAuth();
  const { register, formState: {errors}, handleSubmit } = useForm();

  const onSubmit = async (email: any) => {
    await sendResetPasswordEmail(email);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="content content-fixed content-auth-alt">
        <div className="container d-flex justify-content-center ht-100p">
          <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
            <h4 className="tx-20 tx-sm-24">Нулиране на парола</h4>

            <div className='mt-3'>
              <p className='text-danger'>{error ? errorMessage(error) : ''}</p>
            </div>

            {resetPasswordAction?.emailSent
              ?
              (<p className="tx-color-03 mg-b-30 tx-center">
                Изпратихме линк за потвърждение.
              </p>)
              :
              (<>
                <p className="tx-color-03 mg-b-30 tx-center">
                  Въведете Вашето потребителско име или имейл адрес и ние ще ви изпратим линк за смяна на паролата.
                </p>
                <div className="wd-100p d-flex flex-column flex-sm-row mg-b-40">
                  <input
                    {...register('email', { required: 'Моля въведете имейл' })}
                    id="email"
                    type="email"
                    className="form-control wd-sm-250 flex-fill"
                    placeholder="Потребителско име или имейл адрес"
                  />
                  <button
                    className="btn btn-primary mg-sm-l-10 mg-t-10 mg-sm-t-0"
                    type='submit'
                  >
                    {isLoading
                      ? <span className='spinner-border spinner-border-sm mx-2'/>
                      : 'Изпращане'}

                  </button>
                  {errors.email && (
                    <div className="mt-2 text-xs text-red-600">
                      {errors.email.message}
                    </div>
                  )}
                </div>
              </>)
            }
          </div>
        </div>
      </div>
    </form>
  );
};
