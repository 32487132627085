import { DocumentSnapshot, QuerySnapshot, DocumentData} from '@firebase/firestore-types';
import { removeNullValues } from './shared';
import { Patient, PatientData } from '../../types/patients';

export const transformPatient = (document: DocumentSnapshot): Patient | null => {
  const data = document.data();

  if (!data) {
    throw new Error(`User ${document.id} has invalid data!`);
  }

  return {
    id: document.id,
    ...data as PatientData,
    createdAt: data.createdAt.toDate(),
    updatedAt: data.updatedAt.toDate()
  };
};

export const transformPatients = (querySnapshot: QuerySnapshot<DocumentData>): Patient[] => {
  if (querySnapshot.empty) {
    return [];
  }

  return querySnapshot
    .docs
    .map(transformPatient)
    .filter(removeNullValues) as Patient[];
};
