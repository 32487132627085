import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import { firebaseConfig } from './config/config';

import { InputBase } from '../types/firebase';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const storage = firebase.storage();

export const firestore = firebase.firestore();
// firestore.useEmulator('localhost', 8080);

export const functions = firebase.functions();
// functions.useEmulator('localhost', 5001);

export const auth = firebase.auth();
// auth.useEmulator('http://localhost:9099');


export const createTimestamps = (): InputBase => ({
  createdAt: firebase.firestore.Timestamp.now(),
  updatedAt: firebase.firestore.Timestamp.now()
});
