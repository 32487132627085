import { AuthEmailAction } from './auth-types';

export interface AuthQueryParameters {
  mode: string;
  oobCode: string;
  apiKey: string;
  lang: string;
  continueUrl: string | null;
}

export const getAuthQueryParams = (search: string = window.location.search): AuthQueryParameters | null => {
  const params = new URLSearchParams(search);

  const mode = params.get('mode');
  const oobCode = params.get('oobCode');
  const apiKey = params.get('apiKey');
  const lang = params.get('lang');
  const continueUrl = params.get('continueUrl');

  if (!mode || !oobCode || !apiKey || !lang) {
    return null;
  }

  return {
    mode,
    oobCode,
    apiKey,
    lang,
    continueUrl
  };
};

export const getAuthEmailActionFromAuthQueryParams = (params: AuthQueryParameters | null): AuthEmailAction | null => {
  if (!params) {
    return null;
  }

  switch (params.mode) {
    case 'resetPassword':
      return AuthEmailAction.RESET_PASSWORD;

    case 'recoverEmail':
      return AuthEmailAction.RECOVER_EMAIL;

    case 'verifyEmail':
      return AuthEmailAction.VERIFY_EMAIL;

    case 'signIn':
      return AuthEmailAction.SIGN_IN;

    default:
      return null;
  }
};