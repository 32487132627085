import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useForm } from 'react-hook-form';
import { errorMessage } from '../../errors/errors';
import { AppRoutes } from '../../constants/routes';

interface SignInFormValues {
  email: string;
  password: string;
}

export const SignIn: FC = (): JSX.Element => {
  const { signInWithEmailAndPassword, isLoading, error, user } = useAuth();
  const { register, handleSubmit, formState: { errors } } = useForm<SignInFormValues>();
  const history = useHistory();

  if (user) {
    history.push(AppRoutes.PATIENTS);
  }

  const onSubmit = async (values: SignInFormValues) => {
    await signInWithEmailAndPassword({ email: values.email, password: values.password });
    history.push(AppRoutes.PATIENTS);
  };

  return (
    <div className="content content-fixed content-auth">
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div className="sign-wrapper">
            <div className="wd-100p">
              <h3 className="tx-color-01 mg-b-5">Вход</h3>
              <p className="tx-color-03 tx-16 mg-b-40">Добре дошли! Моля, влезте в профила си за да продължите.</p>

              <div className="mt-3">
                <p className="text-danger">{error ? errorMessage(error) : ''}</p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="email">Имейл адрес</label>
                  <input
                    id="email"
                    type="email"
                    {...register('email', { required: 'Моля въведете имейл' })}
                    className="form-control"
                    placeholder="Въведете своя имейл адрес"
                  />
                  {errors.email && <p className="text-danger">Това поле е задължително!</p>}
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between mg-b-5">
                    <label className="mg-b-0-f" htmlFor="password">Парола</label>
                    <Link to={AppRoutes.FORGOT_PASSWORD} className="tx-13">Забравена парола?</Link>
                  </div>
                  <input
                    id="password"
                    type="password"
                    {...register('password', { required: 'Моля въведете парола' })}
                    className="form-control"
                    placeholder="Въведете своята парола"
                  />
                  {errors.password && <p className="text-danger">Това поле е задължително!</p>}
                </div>
                <button type="submit" className="btn btn-primary btn-block" disabled={isLoading}>Вход</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
